import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import defaultimage from "images/sagra19.png";
import { HandlerTipologiaProps } from "components/SectionHeroArchivePage/SectionHeroArchivePage";

export interface StayCardProps {
  className?: string;
  ratioClass?: string;
  info?: any;
  data?: any;
  onClick?: (tipologia: HandlerTipologiaProps) => void;
  size?: "default" | "small";
}

export interface Etichette {
  id?: number;
  evento_id?: number;
  tipologia_id?: number;
  stato_id?: number;
  etichetta_stato?: string;
}

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data,
  info,
  onClick,
  ratioClass,
}) => {

  const [typeEvent, setTypeEvento] = useState([]);
  const [tipologia, setTipologia] = useState<HandlerTipologiaProps>({});
  useEffect(() => {
    getTypeEvents();
  }, []);

  useEffect(() => {

    if (onClick) {
      if (tipologia != null) {
        onClick(tipologia);
      }
    }

  }, [tipologia]);

  const getTypeEvents = () => {
    fetch(`https://booking.jooba.it/types?id=${data.id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(async (response) => {
        setTypeEvento(response.data);
      })
      .catch((error) => console.log(error));
  };

  const renderBadges = (item: any, etichetta: any) => {

    let bambini = 0;
    let bambiniBaby = 0;
    let animali = true;

    if (info) {
      bambini = info.bambini;
      bambiniBaby = info.bambiniBaby;
      animali = info.animali;
    }

    if (item?.bambini_ammessi == 0 && (bambini + bambiniBaby) > 0) return null;

    if (item?.animali_ammessi == 0 && animali) return null;


    if (item?.stato === 'APERTO') {
      return (
        <button onClick={() =>
          setTipologia({ tipologia_id: item.id, evento_id: item.evento_id })
        } className="w-full mt-2 w-full text-green-800 bg-green-100 animate-pulse nc-Badge px-2.5 py-2 rounded-full font-medium transition-colors hover:bg-green-700 hover:text-white duration-300 text-sm">
          <i className="la la-check-circle mr-4 la-lg"></i>
          {`${item.etichetta !== "" ? item.etichetta : item.tipo}${etichetta !== null ? (etichetta.length > 0 ? ((etichetta[0].etichetta_stato != null && etichetta[0].etichetta_stato !== '') ? `: ${etichetta[0].etichetta_stato}` : '') : '') : ''}`}
        </button>
      );
    }
    if (item?.stato === 'RICHIESTA') {
      return (
        <button onClick={() =>
          setTipologia({ tipologia_id: item.id, evento_id: item.evento_id })
        } className="w-full mt-2 w-full text-yellow-800 bg-yellow-100 animate-pulse nc-Badge px-2.5 py-2 rounded-full font-medium transition-colors hover:bg-yellow-700 hover:text-white duration-300 text-sm">
          <i className="la la-check-circle mr-4 la-lg"></i>
          {`${item.etichetta !== "" ? item.etichetta : item.tipo}${etichetta !== null ? (etichetta.length > 0 ? ((etichetta[0].etichetta_stato != null && etichetta[0].etichetta_stato !== '') ? `: ${etichetta[0].etichetta_stato}` : '') : '') : ''}`}
        </button>
      );
    }
    if (item?.stato === 'ATTESA') {
      return (
        <button onClick={() =>
          setTipologia({ tipologia_id: item.id, evento_id: item.evento_id })
        } className="w-full mt-2 w-full text-blue-800 bg-blue-100 animate-pulse nc-Badge px-2.5 py-2 rounded-full font-medium transition-colors hover:bg-blue-700 hover:text-white duration-300 text-sm">
          <i className="la la-check-circle mr-4 la-lg"></i>
          {`${item.etichetta !== "" ? item.etichetta : item.tipo}${etichetta !== null ? (etichetta.length > 0 ? ((etichetta[0].etichetta_stato != null && etichetta[0].etichetta_stato !== '') ? `: ${etichetta[0].etichetta_stato}` : '') : '') : ''}`}
        </button>
      );
    }
    if (item?.stato === 'CHIUSO') {
      return (
        <button onClick={() => setTipologia({})} className="w-full mt-2 w-full text-red-800 bg-red-100 animate-pulse nc-Badge py-2 rounded-full font-medium text-sm">
          {`${item.etichetta !== "" ? item.etichetta : item.tipo}${etichetta !== null ? (etichetta.length > 0 ? ((etichetta[0].etichetta_stato != null && etichetta[0].etichetta_stato !== '') ? `: ${etichetta[0].etichetta_stato}` : '') : '') : ''}`}
        </button>
      );
    }
    return null;
  };

  const renderChiusoBadges = (etichetteStati: any) => {
    if (etichetteStati && etichetteStati.length > 0) {
      return etichetteStati.map((item: Etichette) => {
        if (item.tipologia_id == null || item.tipologia_id == undefined) {
          return (
            <>
              {item?.stato_id == 3 && item.etichetta_stato != "" && item.etichetta_stato != null && (
                <Badge className="animate-pulse py-2 mr-2 mt-2" name={item.etichetta_stato != "" ? item.etichetta_stato : ''} color="red" />
              )}
            </>
          );
        }
      });
    } else {
      return <Badge className="animate-pulse py-2 mr-2 mt-2" name="CHIUSO" color="red" />;
    }
  };

  const temp_data = data.data;
  let anno = temp_data?.slice(0, 4);
  let mese = temp_data?.slice(5, 7);
  let giorno = temp_data?.slice(8, 10);
  const data_finale = giorno + '/' + mese + '/' + anno;
  const temp_day = new Date(data.data || "").getDay();

  let day = "";

  switch (temp_day) {
    case 0:
      day = "Domenica";

      break;
    case 1:
      day = "Lunedì";

      break;
    case 2:
      day = "Martedì";

      break;
    case 3:
      day = "Mercoledì";

      break;
    case 4:
      day = "Giovedì";

      break;
    case 5:
      day = "Venerdì";

      break;
    case 6:
      day = "Sabato";

      break;
    default:
      break;
  }

  return (
    <div className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow flex flex-col justify-between ${className}`} data-nc-id="StayCard" >
      {data && typeEvent &&
        <>
          <div className="relative w-full">
            {data.image_copertina != ''
              ?
              <img src={`https://private.jooba.it/image/copertina/${data.image_copertina}/${data.id}`} alt="umoya" className="rounded-2xl" />
              :
              <img src={defaultimage} alt="umoya" className="rounded-3xl" />
            }
            <div className="flex flex-col p-4 space-y-2">
              <div className="flex flex-col">
                <div className="flex flex-row text-sm space-x-2">
                  <h2 className={`font-medium capitalize ${size === "default" ? "text-lg" : "text-base"}`}>
                    <span className="line-clamp-2">{data.titolo}</span>
                  </h2>
                </div>
              </div>
              <div className="flex flex-col">
                {(data.aperto == 1 || data.aperto == 0 || data.aperto == 5) &&
                  <>
                    <div className="flex flex-wrap justify-center lg:justify-start">
                      {typeEvent.map((item: any) => {

                        let stato_id = 0;
                        if (item?.stato == 'APERTO') stato_id = 5;
                        else if (item?.stato == "ATTESA") stato_id = 6;
                        else if (item?.stato == "RICHIESTA") stato_id = 7;
                        else if (item?.stato == "CHIUSO") stato_id = 8;
                        let etichetta = data.etichette_stati?.filter((et: any) => et.stato_id === stato_id && et.tipologia_id == item.tipo_id);
                        return renderBadges(item, etichetta);
                      })}
                    </div>
                  </>
                }
                {data.aperto == 3 && (
                  <div className="flex flex-wrap justify-center lg:justify-start">
                    {renderChiusoBadges(data.etichette_stati)}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col p-4 space-y-2">
            <div className="flex flex-col">
              <div className="flex flex-row space-x-4">
                <i className="las la-calendar text-lg"></i>
                <span className="text-sm text-neutral-500 dark:text-neutral-400">
                  {day} {data_finale}
                </span>
              </div>
              <div className="flex flex-row space-x-4">
                <i className="las la-clock text-lg"></i>
                <span className="text-sm text-neutral-500 dark:text-neutral-400" style={{ display: 'inline-block' }}>
                  {data.ora?.slice(0, 5)}-{data.ora_fine?.slice(0, 5)}
                </span>
              </div>
              <Link to={{ pathname: `/dettaglio/${data.id}`, state: { info: info } }} className="w-full mt-5">
                <ButtonPrimary className="w-full rounded-2xl">Scopri di più</ButtonPrimary>
              </Link>
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default StayCard;
