import React, { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import defaultimage from "images/sagra19.png";

export interface ExperiencesCardHProps {
  className?: string;
  id?: string;
  titolo?: string;
  data?: string;
  ora?: string;
  ora_fine?: string;
  stato_aperitivo?: string;
  stato_pranzo?: string;
  aperto?: number;
  image_copertina?: string;
  etichette_stati?: Etichette[];
  label_stato?: string;
}

export interface Etichette {
  id?: number;
  evento_id?: number;
  tipologia_id?: number;
  stato_id?: number;
  etichetta_stato?: string;
}

const ExperiencesCardH: FC<ExperiencesCardHProps> = ({ className = "", id, titolo, data, ora, aperto, ora_fine, image_copertina, etichette_stati, label_stato }) => {

  const [typeEvent, setTypeEvento] = useState([]);
  useEffect(() => {
    getTypeEvents();
  }, [id]);

  const getTypeEvents = () => {
    fetch(`https://booking.jooba.it/types?id=${id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(async (response) => {
        setTypeEvento(response.data);
      })
      .catch((error) => console.log(error));
  };

  const renderSliderGallery = () => {
    return (
      <div className="hidden sm:block text-sm text-neutral-500 dark:text-neutral-400">
        {image_copertina != ''
          ?
          <img src={`https://private.jooba.it/image/copertina/${image_copertina}/${id}`} alt="umoya" style={{ minWidth:"350px", maxWidth:"350px" }} />
          :
          <img src={defaultimage} alt="umoya" style={{ width:"350px" }} />
        }
      </div>
    );
  };

  const temp_data = data;
  let anno = temp_data?.slice(0, 4);
  let mese = temp_data?.slice(5, 7);
  let giorno = temp_data?.slice(8, 10);
  const data_finale = giorno + '/' + mese + '/' + anno;
  const temp_day = new Date(data || "").getDay();

  let day = "";

  switch (temp_day) {
    case 0:
      day = "Domenica";

      break;
    case 1:
      day = "Lunedì";

      break;
    case 2:
      day = "Martedì";

      break;
    case 3:
      day = "Mercoledì";

      break;
    case 4:
      day = "Giovedì";

      break;
    case 5:
      day = "Venerdì";

      break;
    case 6:
      day = "Sabato";

      break;
    default:
      break;
  }

  const renderContent = () => {

    return (
      <div className="flex-grow p-5 flex flex-col self-center">
        {(aperto == 1 || aperto == 0) &&
          <>
            {etichette_stati &&
              <div className="flex flex-wrap justify-center lg:justify-start">
                {etichette_stati.length > 0 && etichette_stati.map((item: Etichette) => {
 
                  if (item.tipologia_id == null || item.tipologia_id == undefined) {
                    return <>
                      {item?.stato_id == 1 && item.etichetta_stato != "" && item.etichetta_stato != null &&
                        <Badge className="animate-pulse py-2 mr-2 mt-2" name={(item.etichetta_stato != "" && item.etichetta_stato != null) ? item.etichetta_stato : ''} color="blue" />
                      }
                      {/* {item?.stato_id == 2 && item.etichetta_stato != "" && item.etichetta_stato != null &&
                        <Badge className="animate-pulse py-2 mr-2 mt-2" name={(item.etichetta_stato != "" && item.etichetta_stato != null) ? item.etichetta_stato : ''} color="yellow" />
                      } */}
                      {/* {item?.stato_id == 3 && item.etichetta_stato != "" && item.etichetta_stato != null &&
                        <Badge className="animate-pulse py-2 mr-2 mt-2" name={(item.etichetta_stato != "" && item.etichetta_stato != null) ? item.etichetta_stato : ''} color="blue" />
                      } */}
                      {/* {item?.stato_id == 4 && item.etichetta_stato != "" && item.etichetta_stato != null &&
                        <Badge className="animate-pulse py-2 mr-2 mt-2" name={(item.etichetta_stato != "" && item.etichetta_stato != null) ? item.etichetta_stato : ''} color="red" />
                      } */}
                    </>
                  }
                })}
              </div>
            }
            <div className="flex flex-wrap justify-center lg:justify-start">
              {typeEvent.map((item: any) => {
                let etichetta = etichette_stati?.filter((x) => {                  
                  if(x.tipologia_id == item.id){
                    if(item?.stato == 'APERTO' && item.stato_id == 5) {
                      return x;

                    } else if(item?.stato == 'ATTESA' && item.stato_id == 6) {
                      return x;

                    } else if(item?.stato == 'RICHIESTA' && item.stato_id == 7) {
                      return x;
                      
                    } else if(item?.stato == 'CHIUSO' && item.stato_id == 8) {
                      return x;
          
                    } else return [];
                  }
                });

                return <>
                  {item?.stato == 'APERTO' &&
                    <Badge className="animate-pulse py-2 mr-2 mt-2" name={((item.etichetta != "") ? item.etichetta : item.tipo)+' '+(etichetta != null ? (etichetta.length>0 ? ((etichetta[0].etichetta_stato != null && etichetta[0].etichetta_stato != '') ? ': '+etichetta[0].etichetta_stato : '') : '') : '') } color="green" />
                  }
                  {item?.stato == 'RICHIESTA' &&
                    <Badge className="animate-pulse py-2 mr-2 mt-2" name={((item.etichetta != "") ? item.etichetta : item.tipo)+' '+(etichetta != null ? (etichetta.length>0 ? ((etichetta[0].etichetta_stato != null && etichetta[0].etichetta_stato != '') ? ': '+etichetta[0].etichetta_stato : '') : '') : '') } color="yellow" />
                  }
                  {item?.stato == 'ATTESA' &&
                    <Badge className="animate-pulse py-2 mr-2 mt-2" name={((item.etichetta != "") ? item.etichetta : item.tipo)+' '+(etichetta != null ? (etichetta.length>0 ? ((etichetta[0].etichetta_stato != null && etichetta[0].etichetta_stato != '') ? ': '+etichetta[0].etichetta_stato : '') : '') : '') } color="blue" />
                  }
                  {item?.stato == 'CHIUSO' &&
                    <Badge className="animate-pulse py-2 mr-2 mt-2" name={((item.etichetta != "") ? item.etichetta : item.tipo)+' '+(etichetta != null ? (etichetta.length>0 ? ((etichetta[0].etichetta_stato != null && etichetta[0].etichetta_stato != '') ? ': '+etichetta[0].etichetta_stato : '') : '') : '') } color="red" />
                  }
                </>
              })}
            </div>
          </>
        }
        {(aperto == 3) &&
          <div className="flex flex-wrap justify-center lg:justify-start">
              {etichette_stati &&
              <div className="flex flex-wrap justify-center lg:justify-start">
                {etichette_stati.length > 0 && etichette_stati.map((item: Etichette) => {
                  if (item.tipologia_id == null || item.tipologia_id == undefined) {
                    return <>
                      {item?.stato_id == 3 && item.etichetta_stato != "" && item.etichetta_stato != null &&
                        <Badge className="animate-pulse py-2 mr-2 mt-2" name={(item.etichetta_stato != "") ? item.etichetta_stato : ''} color="red" />
                      }
                    </>
                  }
                })}
                {etichette_stati.length == 0 &&
                  <Badge className="animate-pulse py-2 mr-2 mt-2" name="CHIUSO" color="red" />
                }
              </div>
            }
          </div>
        }
        {/* {(aperto == 4) &&
          <div className="flex flex-wrap justify-center lg:justify-start"> <Badge className="animate-pulse py-2 mr-2 mt-2" name="ANNULLATO" color="red" /></div>
        } */}
        <div className="flex flex-col py-4">
          <h2 className="text-lg text-primary-6000 font-bold capitalize">
            {titolo}
          </h2>
          <span className="text-sm text-primary-6000">
            {label_stato}
          </span>
        </div>

        <div className="flex space-x-8">
          <div className="flex items-center space-x-2">
            <i className="las la-calendar text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {day} {data_finale}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <i className="las la-clock text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400" style={{ display: 'inline-block' }}>
              {ora?.slice(0, 5)}-{ora_fine?.slice(0, 5)}
            </span>
          </div>
        </div>
      </div>
    );
  };
  // rounded-2xl per il bordo arrontondato
  return (
    <div
      className={`nc-ExperiencesCardH group relative bg-white dark:bg-neutral-900 border border-2 border-primary-500 dark:border-neutral-800 overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="ExperiencesCardH"
    >

      <Link to={{ pathname: `/dettaglio/${id}` }} className="md:flex md:flex-row">
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default ExperiencesCardH;
