import React, { FC, useEffect, useState } from "react";
import GuestsInputMirko from "components/HeroSearchForm/GuestsInputMirko";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useHistory } from 'react-router-dom'
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import ExperiencesDateSingleInput from "components/HeroSearchForm/ExperiencesDateSingleInput";
import image1 from "images/umoya/lodge.jpeg";
import image2 from "images/umoya/lodge1.jpeg";
import image3 from "images/umoya/lodge2.jpeg";
import image4 from "images/umoya/lodge3.jpeg";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ModalPhotos from "./ModalPhotos";
import GuestsInputSolarium from "components/HeroSearchForm/GuestsInputSolarium";
import moment from 'moment';
import 'moment/locale/it';
import FlightCardMirko from "components/FlightCard/FlightCardMirko";
import FlightCardNote from "components/FlightCard/FlightCardNote";
import GuestsInputBamibiCheck from "components/HeroSearchForm/GuestsInputBambiniCheck";
import GuestsInputBambini from "components/HeroSearchForm/GuestsInputBambini";
import GuestsInputAnimali from "components/HeroSearchForm/GuestsInputAnimali";
import GuestInputFasce from "components/HeroSearchForm/GuestsInputFasce";
import GuestInputSceltaRistorante from "components/HeroSearchForm/GuestsInputSceltaRistorante";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SaleOffBadgeGrey from "components/SaleOffBadge/SaleOffBadgeGrey";
import RentalCarDatesRangeInput from "components/HeroSearchForm/RentalCarDatesRangeInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import RentalCarSearchForm, { TimeRage } from "components/HeroSearchForm/RentalCarSearchForm";
import { FocusedInputShape } from "react-dates";
import RentalCarSearchFormMirko from "components/HeroSearchForm/RentalCarSearchFormMirko";

export interface ListingExperiencesDetailLodge {
  className?: string;
  id?: string;
  titolo?: string;
  tariffaAdulto?: string;
  tariffaBambino?: string;
  item?: [];
}

interface GuestsInputPropsBambiniCk {

  guestBambiniCk?: boolean;
  clicked?: boolean;

}
interface GuestInputTipoRistorante {

  guestTipoRistorante?: string;

}

export interface FasciaOraria {
  id?: number;
  fascia?: string;
  giacenza?: number;
  tipo?: string;
}

export interface test {
  id?: string;
  titolo?: string;
  des_offerta?: string;
}

interface GuestsInputPropsAnimali {

  guestAnimals?: boolean;

}

const options = [
  { value: 'PRANZO', label: 'PRANZO' },
  { value: 'CENA', label: 'CENA' },
]

interface State {
  id: string,
}
interface GuestsInputPropsBambini {

  guestChildren?: number;
  guestChildrenBaby?: number;

}

interface GuestsInputProps {

  guestAdults?: number;

}

const ListingExperiencesDetailLodge: FC<ListingExperiencesDetailLodge> = ({ className = "", ...props }) => {

  //Image
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };
  const handleCloseModal = () => setIsOpen(false);
  //End image

  const [dateFocused, setDateFocused] = useState<boolean>(false);
  const [dateValue, setdateValue] = useState<moment.Moment | null>(null);
  const [bambinick, setBambiniCk] = useState(false);
  const [tariffaAdulto, setTariffaAdulto] = useState('');
  const [tariffaBambino, setTariffaBambino] = useState('');
  const [paga_online, setPagaOnline] = useState('');
  const [paga_struttura, setPagaStruttura] = useState('');
  const [max_persone, setMaxPersone] = useState(0);
  const [min_persone, setMinPersone] = useState(1);
  const [stato, setStato] = useState('');
  const [stato_originale, setStatoOriginale] = useState('');
  const [val_pren, setValutazione] = useState('');
  const [typeSerives, setTypeServices] = useState<any>([]);
  const [verificatoButton, setVerificatoButton] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fasciaOraria, setFasciaOrariaArray] = useState<any>([]);
  const [fasciaorariaselezionata, setFasciaOraria] = useState('');


  // Numero di aulti e bambini
  const [adulti, setAdulti] = useState(0);
  const [bambini, setBambini] = useState(0);
  const [bambiniBaby, setBambiniBaby] = useState(0);
  const [animali, setAnimali] = useState(false);
  const [tipologia, setTipologia] = useState('');
  const [arrayScelto, setArrayScelto] = useState<any>([]);
  const [aperto, setAperto] = useState(1);
  const [totalePersone, setTotalePersone] = useState(0);
  const [disponibilita, setDisponibilita] = useState(true);
  const [area, setArea] = useState('');
  const [area_id, setAreaId] = useState(0);
  const [service_id, setServicesId] = useState(0);
  const [quota_una_tantum, setUnatantum] = useState(0);
  const [ta, setTA] = useState('');
  const [tb, setTB] = useState('');
  const [note_generali, setNoteGenerali] = useState('');
  const [title_note_generali, setTitleNoteGenerali] = useState('');
  const [title_note_token, setTitleNoteToken] = useState('');
  const [note_token, setNotetoken] = useState('');
  const [titolo_iniziale, setTitoloIniziale] = useState('');
  const [stato_anagrafato_negativo, setStatoAnagrafatoNeg] = useState('');
  const [stato_anagrafato_positivo, setStatoAnagrafatoPos] = useState('');
  const [stato_nuovo_cliente, setStatoNuovoCliente] = useState('');
  const [stato_bannato, setStatoBannato] = useState('');
  const [stato_tesserato, setStatoTesserato] = useState('');
  const [disponibile_cena, setDisponibileCena] = useState('');
  const [disponibile_pranzo, setDisponibilePranzo] = useState('');
  const accordion_bambini = arrayScelto?.accordion_bambini;
  const accordion_richiestadiprenotazione = arrayScelto?.accordion_richiestediprenotazione;
  const accordion_listadiattesa = arrayScelto?.accordion_listadiattesa;
  const testo_accordion_bambini = arrayScelto?.testo_accordion_bambini;
  const testo_accordion_richiestadiprenotazione = arrayScelto?.testo_accordion_richiestediprenotazione;
  const testo_accordion_listadiattesa = arrayScelto?.testo_accordion_listadiattesa;


  const animali_ammessi = arrayScelto?.animali_ammessi || 1;
  const bambini_ammessi = arrayScelto?.bambini_ammessi || 1;
  const label_titolo = arrayScelto?.label_titolo;


  const [animalick, setAnimaliChecked] = useState(false);
  const [bambinisel, setBambiniSel] = useState(false);
  const [tiporistorante, setTipoRistorante] = useState('');


  //Miei controlli
  const [alertData, setAlertData] = useState(false);
  const [alertTipologia, setAlertTipologia] = useState(false);
  const [alertAdulti, setAlertAdulti] = useState(false);
  const [alertBambiniChecked, setAlertBambiniChecked] = useState(false);
  const [alertBambini, setAlertBambini] = useState(false);
  const [alertAnimali, setAlertAnimali] = useState(false);


  var tipologies = new Array();
  typeSerives.map((item: any) => {
    tipologies.push([item.riferimento_setting, '', item.stato]);
  });

  var fasce_orarie = new Array();

  fasciaOraria.map((item: any) => {
    fasce_orarie.push([item.id, item.fascia, item.giacenza, item.tipo]);
  });

  const data_finale = dateValue?.format('l');

  const handlerBambiniCk = (person: GuestsInputPropsBambiniCk) => {

    setLoading(false);
    setVerificatoButton(0);
    setBambiniCk(person.guestBambiniCk || false);
    if (person.clicked === true) {
      setBambiniSel(true);
    }
    setBambini(0);
    setBambiniBaby(0);
    setAlertBambiniChecked(false);

  };

  const handlerSceltaRistorante = (tiporistorante: GuestInputTipoRistorante) => {
    setLoading(false); setVerificatoButton(0);
    setTipoRistorante(tiporistorante.guestTipoRistorante || '')
  };

  const handlerBambini = (person: GuestsInputPropsBambini) => {
    setLoading(false); setVerificatoButton(0);
    setBambini(person.guestChildren || 0)
    setBambiniBaby(person.guestChildrenBaby || 0);
    setAlertBambini(false);
  }

  const handlerAnimali = (person: GuestsInputPropsAnimali) => {

    if (animali_ammessi == 1) {

      setLoading(false);
      setVerificatoButton(0);
      setAnimali(person.guestAnimals || false);
      setAnimaliChecked(true);
      setAlertAnimali(false);

    } else setAnimaliChecked(true);

  }

  var history = useHistory();
  const MySwal = withReactContent(Swal)

  useEffect(() => {

    getTariffeService();
    getPresenzaEvento();

  }, [tipologia]);

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    const tmp = adulti;

    if (tmp > 0 && min_persone > 0 && max_persone > 0) {

      if (tmp > max_persone) {

        MySwal.fire({
          title: <p>Attenzione</p>,
          html: <p>Numero massimo di partecipanti superato!</p>,
          confirmButtonColor: "#aa7832a8",
          icon: "error",
          //footer: 'Copyright 2018',
          didOpen: () => {
            //MySwal.clickConfirm()
          }
        }).then(() => {
          //return MySwal.fire(<p>Shorthand works too</p>)
        })
      }
    }
    setTotalePersone(tmp);

  }, [adulti, min_persone, max_persone, bambini]);

  const getServices = () => {
    fetch(`https://booking.jooba.it/services`, {
      method: 'GET'
    })
      .then((response) => response.json())
      .then(async (response) => {
        setTitoloIniziale('Seleziona un servizio per conoscerne i dettagli');
        setTypeServices(response);
      })
      .catch((error) => console.log(error));
  };

  const getPresenzaEventoRistorante = () => {

    if (tipologia == "RISTORANTE" || tipologia == "APERITIVO") {
      fetch(`https://booking.jooba.it/checkPresenzaEventoRistorante?data=${data_finale}&tipo=${tiporistorante}`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then(async (response) => {
          if (response.evento === true) {
            setTipoRistorante('');
            MySwal.fire({
              title: <p>Attenzione</p>,
              html: <p>Il servizio in questa data è disponibile solo tramite evento!</p>,
              icon: "warning",
              confirmButtonColor: "rgb(228, 231, 231)",
              cancelButtonColor: "#aa7832a8",
              cancelButtonText: 'Vai agli eventi',
              showCancelButton: true,
              didOpen: () => {
              }
            }).then((result) => {
              if (result.isDismissed === true) {
                history.push('/eventi');
              }
            })
          }
        })
        .catch((error) => console.log(error));
    }

  };

  const getPresenzaEvento = () => {

    if (tipologia == "SOLARIUM") {
      fetch(`https://booking.jooba.it/checkPresenzaEvento?data=${data_finale}`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then(async (response) => {
          if (response.evento === true) {
            setdateValue(null);
            MySwal.fire({
              title: <p>Attenzione</p>,
              html: <p>Il servizio in questa data è disponibile solo tramite evento!</p>,
              icon: "warning",
              confirmButtonColor: "rgb(228, 231, 231)",
              cancelButtonColor: "#aa7832a8",
              cancelButtonText: 'Vai agli eventi',
              showCancelButton: true,
              didOpen: () => {
              }
            }).then((result) => {
              if (result.isDismissed === true) {
                history.push('/eventi');
              }
            })
          }
        })
        .catch((error) => console.log(error));
    }

  };

  const getDisponibilita = () => {

    getPresenzaEventoRistorante();

    if (!(tipologia != '' && totalePersone >= min_persone && totalePersone <= max_persone && verificatoButton == 0 && animalick == true)) {
      if ((tipologia != "RISTORANTE" && tipologia != "APERITIVO")) {
        MySwal.fire({
          title: <p>Attenzione</p>,
          html: <p>Devi specificare tutti i campi (Data, tipo prenotazione, adulti, Bambini si/no, animali si/no) etc!</p>,
          confirmButtonColor: "#aa7832a8",
          icon: "error",
          //footer: 'Copyright 2018',
          didOpen: () => {
            //MySwal.clickConfirm()
          }
        }).then(() => {
          //return MySwal.fire(<p>Shorthand works too</p>)
        })

        if (data_finale == undefined) {
          setAlertData(true);
          return;
        } else setAlertData(false);

        if (tipologia == "") {
          setAlertTipologia(true);
          return;
        } else setAlertTipologia(false);

        if (adulti == 0) {
          setAlertAdulti(true);
          return;
        } else setAlertAdulti(false);

        if (bambinisel == false) {
          setAlertBambiniChecked(true);
          return;
        } else setAlertBambiniChecked(false);

        if (bambinick == true && (bambini + bambiniBaby) == 0) {
          setAlertBambini(true);
          return;
        } else setAlertBambini(false);

        if (animalick == false) {
          setAlertAnimali(true);
          return;
        } else setAlertAnimali(false);

        return;
      }
    }
    if (!(bambinisel === true && ((bambinick === true && bambini > 0) || bambinick === false))) {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Devi specificare tutti i campi (Data, tipo prenotazione, adulti, Bambini si/no, animali si/no) etc!</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        }
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      })
      return;
    }

    if (tipologia == "RISTORANTE" && fasciaorariaselezionata == "") {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Devi specificare tutti i campi (Data, tipo prenotazione, adulti, Bambini si/no, fascia oraria, animali si/no) etc!</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        }
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      })
      return;
    }

    setLoading(true);

    fetch(`https://booking.jooba.it/check_giacenza?service_id=${service_id}&type=S&data=${data_finale}&adulti=${adulti}&bambini=${bambini}&animali=${(animali == true) ? 1 : 0}&fascia_oraria=${fasciaorariaselezionata}&tipo_risto=${tiporistorante}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(async (response) => {
        if (response.reponse === true) {
          setStato(stato_originale)
          setTimeout(() => {
            if (response.evento === true) {
              setLoading(false);
              MySwal.fire({
                title: <p>Attenzione</p>,
                html: <p>I servizi in questa data sono disponibili solo tramite evento!</p>,
                icon: "warning",
                confirmButtonColor: "rgb(228, 231, 231)",
                cancelButtonColor: "#aa7832a8",
                cancelButtonText: 'Vai agli eventi',
                showCancelButton: true,
                didOpen: () => {
                }
              }).then((result) => {
                if (result.isDismissed === true) {
                  history.push('/eventi');
                }
                setdateValue(null);
              })
            } else {

              setLoading(false);
              setVerificatoButton(1);
              setDisponibilita(response.reponse)
              setArea(response.area)
              setAreaId(response.area_id)
            }
          }, 600);

        } else {

          setLoading(false);
          setVerificatoButton(1);
          setStato('ATTESA');
        }
      })
      .catch((error) => console.log(error));
  };

  const getTariffeService = () => {
    if (data_finale != "" && tipologia != "") {
      fetch(`https://booking.jooba.it/getTariffeService?data=${data_finale}&service=${tipologia}`, {
        method: 'GET'
      })
        .then((response) => response.json())
        .then(async (response) => {
          if (response == 0) {
            MySwal.fire({
              title: <p>Attenzione</p>,
              html: <p>Il servizio in questa data non è disponibile!</p>,
              icon: "warning",
              confirmButtonColor: "#aa7832a8",
              cancelButtonText: 'Vai agli eventi',
              showCancelButton: false,
              didOpen: () => {
              }
            }).then((result) => {
              setdateValue(null);
            })
          } else {
            setStatoAnagrafatoNeg(response.stato_anagrafato_negativo);
            setStatoAnagrafatoPos(response.stato_anagrafato_positivo);
            setStatoNuovoCliente(response.stato_nuovo_cliente);
            setStatoBannato(response.stato_bannato);
            setStatoTesserato(response.stato_tesserato);
            setTariffaAdulto(response.tariffa_adulto)
            setTariffaBambino(response.tariffa_bambino)
            setValutazione(response.valutazione)
            setStato(response.stato)
            setStatoOriginale(response.stato)
            setPagaOnline(response.paga_online)
            setPagaStruttura(response.paga_struttura)
            setMaxPersone(response.max_pren)
            setMinPersone(response.min_pren)
            setNotetoken(response.note_token)
            setNoteGenerali(response.note_generali)
            setTitleNoteGenerali(response.title_note_generali)
            setTitleNoteToken(response.title_note_token)
            setDisponibileCena(response.disponibile_cena);
            setDisponibilePranzo(response.disponibile_pranzo);
          }

        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    if (tiporistorante != "") {
      getPresenzaEventoRistorante();
      getFasciaOraria();
    }
  }, [tiporistorante]);

  const getFasciaOraria = () => {
    fetch(`https://booking.jooba.it/getFasceOrarie?tipo=${tiporistorante}&data=${data_finale}`, {
      method: 'GET'
    })
      .then((response) => response.json())
      .then(async (response) => {
        setFasciaOrariaArray(response.data);
      })
      .catch((error) => console.log(error));
  };

  const PHOTOS: string[] = [
    image1,
    image2,
    image3,
    image4
  ];

  const tipoHandler = (tipo: string, index: number) => {

    setLoading(false); setVerificatoButton(0);
    setAdulti(0);
    if (tipologies[index]) {
      let arrayScel = typeSerives.filter((c: any) => c.riferimento_setting == tipo)[0];
      setServicesId(arrayScel.id);
      setArrayScelto(arrayScel);
      setTipologia(tipo);
      setAlertTipologia(false);
    }

  }

  const tipoHandlerFasce = (fasciaselezionata: string, index: number) => {
    setLoading(false); setVerificatoButton(0);
    setFasciaOraria(fasciaselezionata);
  }

  const test = (person: GuestsInputProps) => {
    setLoading(false);
    setVerificatoButton(0);
    setAdulti(person.guestAdults || 0)
    setAlertAdulti(false);
  }

  const buttonVerificaDisp = () => {
    return (
      <ButtonPrimary className="w-full" onClick={getDisponibilita} loading={loading}>
        Verifica disponibilità
      </ButtonPrimary>
    );
  };

  const renderFasceOrarie = () => {
    var totPers = adulti + bambini;

    if (bambinick === true && bambini > 0) {
      return (
        <div className="flex-1 border divide-x divide-neutral-200 border-primary-100 rounded-md mt-4">
          <div className="flex flex-row">
            <GuestInputFasce onChange={tipoHandlerFasce} fasceorarie={fasce_orarie} data={data_finale} totalepersone={totPers} />
          </div>
        </div>
      );
    } else if (bambinick === false) {
      return (
        <div className="flex-1 border divide-x divide-neutral-200 border-primary-100 rounded-md mt-4">
          <div className="flex flex-row">
            <GuestInputFasce onChange={tipoHandlerFasce} fasceorarie={fasce_orarie} data={data_finale} totalepersone={totPers} />
          </div>
        </div>
      );
    }
  }

  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: null,
    endDate: null,
  });

  const [timeRangeValue, setTimeRangeValue] = useState<TimeRage>({
    startTime: "10:00 AM",
    endTime: "10:00 AM",
  });

  const [fieldFocused, setFieldFocused] = useState<
  FocusedInputShape | "dropOffInput" | null
>(null);

  const renderInputData = () => {
    let classNameNormale = 'flex-1 border divide-x divide-neutral-200 border-primary-100 rounded-md mt-4';
    let classNameErrore = 'flex-1 border border-2 divide-x divide-neutral-200 border-red-500 rounded-md mt-4';
    let classNameNew = '';

    if (alertData) {
      classNameNew = classNameErrore;
    } else classNameNew = classNameNormale;

    return (
      <div className={classNameNew}>
        {alertData &&
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-red-500 text-white mr-4" desc="Necessario per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        }
        {data_finale == undefined && !alertData &&
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-primary-100 text-white mr-4" desc="Seleziona per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        }
        <div className="flex flex-row">
          <RentalCarSearchFormMirko haveDefaultValue={true} />
        </div>
      </div>
    );
  };

  const renderTipologia = () => {
    let classNameNormale = 'flex-1 border divide-x divide-neutral-200 border-primary-100 rounded-md mt-4';
    let classNameErrore = 'flex-1 border border-2 divide-x divide-neutral-200 border-red-500 rounded-md mt-4';
    let classNameNew = '';

    if (alertTipologia) {
      classNameNew = classNameErrore;
    } else classNameNew = classNameNormale;

    return (
      <div className={classNameNew}>
        {alertTipologia &&
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-red-500 text-white mr-4" desc="Necessario per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        }
        {tipologia == "" && !alertTipologia &&
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-primary-100 text-white mr-4" desc="Seleziona per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        }
        <div className="flex flex-row">
          <GuestsInputMirko onChange={tipoHandler} tipologies={tipologies} data={data_finale} fieldClassName="p-5 -mt-3" />
        </div>
      </div>
    );
  };

  const renderSceltaRistorante = () => {
    return (
      <div className="flex-1 border divide-x divide-neutral-200 border-primary-100 rounded-md mt-4">
        <div className="flex flex-row">
          <SaleOffBadgeGrey colore="bg-primary-100 text-white mr-4" desc="Seleziona per continuare" className="-mt-3 ml-auto py-0.5" />
        </div>
        <div className="flex flex-row">
          <GuestInputSceltaRistorante fieldClassName="p-5 -mt-3"
            defaultValue={{
              guestTipoRistorante: 'PRANZO',
              disponibile_cena: disponibile_cena,
              disponibile_pranzo: disponibile_pranzo
            }}
            onChange={handlerSceltaRistorante}
          />
        </div>
      </div>
    );
  };

  const renderAdulti = () => {
    let classNameNormale = 'flex-1 border divide-x divide-neutral-200 border-primary-100 rounded-md mt-4';
    let classNameErrore = 'flex-1 border border-2 divide-x divide-neutral-200 border-red-500 rounded-md mt-4';
    let classNameNew = '';

    if (alertAdulti) {
      classNameNew = classNameErrore;
    } else classNameNew = classNameNormale;

    return (
      <div className={classNameNew}>
        {alertAdulti &&
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-red-500 text-white mr-4" desc="Necessario per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        }
        {adulti == 0 && !alertAdulti &&
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-primary-100 text-white mr-4" desc="Seleziona per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        }
        <div className="flex flex-row">
          <GuestsInputSolarium
            fieldClassName="p-5 -mt-3"
            defaultValue={{
              guestAdults: adulti,
              max_persone: max_persone,
              min_persone: min_persone
            }}
            onChange={test}
          />
        </div>
      </div>
    );
  };

  const renderInputBambiniCk = () => {
    let classNameNormale = 'flex-1 border divide-x divide-neutral-200 border-primary-100 rounded-md mt-4';
    let classNameErrore = 'flex-1 border border-2 divide-x divide-neutral-200 border-red-500 rounded-md mt-4';
    let classNameNew = '';

    if (alertBambiniChecked) {
      classNameNew = classNameErrore;
    } else classNameNew = classNameNormale;

    return (
      <div className={classNameNew}>
        {alertBambiniChecked &&
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-red-500 text-white mr-4" desc="Necessario per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        }
        {bambinisel == false && !alertBambiniChecked &&
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-primary-100 text-white mr-4" desc="Seleziona per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        }
        <div className="flex flex-row">
          <GuestsInputBamibiCheck fieldClassName="p-5 -mt-3"
            defaultValue={{
              guestBambiniCk: bambinick,
              clicked: false,
              bambini_ammessi: bambini_ammessi
            }}
            onChange={handlerBambiniCk}
          />
        </div>
      </div>
    );
  };

  const renderInputBambini = () => {
    let classNameNormale = 'flex-1 border divide-x divide-neutral-200 border-primary-100 rounded-md mt-4';
    let classNameErrore = 'flex-1 border border-2 divide-x divide-neutral-200 border-red-500 rounded-md mt-4';
    let classNameNew = '';

    if (alertBambini) {
      classNameNew = classNameErrore;
    } else classNameNew = classNameNormale;

    return (
      <div className={classNameNew}>
        {alertBambini &&
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-red-500 text-white mr-4" desc="Necessario per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        }
        {(bambini + bambiniBaby) == 0 && !alertBambini &&
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-primary-100 text-white mr-4" desc="Seleziona per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        }
        <div className="flex flex-row">
          <GuestsInputBambini fieldClassName="p-5 -mt-3"
            defaultValue={{
              guestChildren: bambini,
              guestChildrenBaby: bambiniBaby
            }}
            onChange={handlerBambini}
          />
        </div>
      </div>
    );
  };

  const renderInputAnimali = () => {
    let classNameNormale = 'flex-1 border divide-x divide-neutral-200 border-primary-100 rounded-md mt-4';
    let classNameErrore = 'flex-1 border border-2 divide-x divide-neutral-200 border-red-500 rounded-md mt-4';
    let classNameNew = '';

    if (alertAnimali) {
      classNameNew = classNameErrore;
    } else classNameNew = classNameNormale;

    return (
      <div className={classNameNew}>
        {alertAnimali &&
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-red-500 text-white mr-4" desc="Necessario per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        }
        {animalick == false && !alertAnimali &&
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-primary-100 text-white mr-4" desc="Seleziona per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        }
        <div className="flex flex-row">
          <GuestsInputAnimali fieldClassName="p-5 -mt-3"
            defaultValue={{
              guestAnimals: animali,
              animali_ammessi: animali_ammessi,
            }}
            onChange={handlerAnimali}
          />
        </div>
      </div>
    );
  };

  const checkoutRender = () => {
    if ((tipologia != "RISTORANTE" && tipologia != "APERITIVO")) {
      return (
        <Link to={{ pathname: '/checkoutsolarium', state: { stato_anagrafato_negativo, stato_anagrafato_positivo, stato_nuovo_cliente, stato_tesserato, stato_bannato, tiporistorante, fasciaorariaselezionata, data_finale, adulti, bambini, animali, tariffaAdulto, tariffaBambino, tipologia, service_id, paga_online, paga_struttura, val_pren, stato, quota_una_tantum, note_generali, note_token, title_note_generali, title_note_token, ta, tb, area, area_id, accordion_bambini, accordion_listadiattesa, accordion_richiestadiprenotazione, testo_accordion_bambini, testo_accordion_listadiattesa, testo_accordion_richiestadiprenotazione } }} >
          <ButtonSecondary className="w-full">
            Vai al checkout
          </ButtonSecondary>
        </Link>
      );
    } else {
      return (
        <Link to={{ pathname: '/checkoutristorante', state: { stato_anagrafato_negativo, stato_anagrafato_positivo, stato_nuovo_cliente, stato_tesserato, stato_bannato, tiporistorante, fasciaorariaselezionata, data_finale, adulti, bambini, animali, tariffaAdulto, tariffaBambino, tipologia, service_id, paga_online, paga_struttura, val_pren, stato, quota_una_tantum, note_generali, note_token, title_note_generali, title_note_token, ta, tb, area, area_id, accordion_bambini, accordion_listadiattesa, accordion_richiestadiprenotazione, testo_accordion_bambini, testo_accordion_listadiattesa, testo_accordion_richiestadiprenotazione } }} >
          <ButtonSecondary className="w-full">
            Vai al checkout
          </ButtonSecondary>
        </Link>
      );
    }
  };

  const goDescription = () => {
    return (
      <div className="flex flex-col space-y-2">
        <div className="flex justify-center text-neutral-6000 dark:text-neutral-300">
          {arrayScelto.stato == 'RICHIESTA' &&
            <span>{(arrayScelto.label_stato != "" && arrayScelto.label_stato != null) ? arrayScelto.label_stato : 'Stai effettuando una richiesta di prenotazione per:'}</span>
          }
          {arrayScelto.stato == 'APERTO' &&
            <span>{(arrayScelto.label_stato != "" && arrayScelto.label_stato != null) ? arrayScelto.label_stato : 'Stai effettuando una prenotazione per:'}</span>
          }
        </div>
        <div className="py-1"></div>
        <div className="flex justify-center text-neutral-6000 dark:text-neutral-300">
          {tipologia == "RISTORANTE"
            ?
            <span className="font-bold" style={{ fontSize: 20 }}>{tiporistorante} - fascia oraria: {fasciaorariaselezionata}</span>
            :
            <span className="font-bold" style={{ fontSize: 20 }}>{tipologia}</span>
          }
        </div>
        <div className="flex justify-center text-neutral-6000 dark:text-neutral-300">
          <span className="font-bold" style={{ fontSize: 20 }}>{data_finale}</span>
        </div>
        <div className="py-2">
          <hr />
        </div>
        {(tipologia != "RISTORANTE" && tipologia != "APERITIVO")
          ?
          <>
            {adulti > 0 &&
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="text-sm text-neutral-500"><i className="las la-male text-lg mr-2"></i>Tariffa adulti</span> <span className="text-sm text-neutral-500">€ {parseFloat(tariffaAdulto).toFixed(2)} x{adulti}</span>
              </div>
            }
          </>
          :
          <>
            {adulti > 0 &&
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="text-sm text-neutral-500"><i className="las la-male text-lg mr-2"></i>Adulti</span> <span className="text-sm text-neutral-500">{adulti}</span>
              </div>
            }
          </>
        }
        {bambini_ammessi == 1 ?
          <>
            {(bambini + bambiniBaby > 0) &&
              <>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span className="text-sm text-neutral-500"><i className="las la-child text-lg mr-2"></i>Bambini Da 4 a 12 anni</span>
                  <span className="text-sm text-neutral-500">{bambini > 0 && (tipologia != "RISTORANTE" && tipologia != "APERITIVO")
                    ? `€ ${tariffaBambino} x ${bambini}`
                    : bambini
                  }
                  </span>
                </div>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span className="text-sm text-neutral-500"><i className="las la-baby text-lg mr-2"></i>Bambini da 0 a 3 anni</span>
                  <span className="text-sm text-neutral-500">Gratis</span>
                </div>
              </>
            }
          </>
          :
          <div className="flex items-center" style={{ justifyContent: "space-between" }}>
            <div>
              <i className="las la-child text-lg mr-2"></i>
              <span className="text-sm text-neutral-500">Bambini</span>
            </div>
            <div className="flex flex-col ml-auto">
              <span className="text-sm text-neutral-500">Non ammessi</span>
            </div>
          </div>
        }

        {animali_ammessi == 1 ?
          <>
            {animalick === true &&
              <div className="flex items-center" style={{ justifyContent: "space-between" }}>
                <div>
                  <i className="las la-dog text-lg mr-2"></i>
                  <span className="text-sm text-neutral-500">Animali</span>
                </div>
                <div className="flex flex-col ml-auto">
                  <span className="text-sm text-neutral-500">{animali ? 'Si' : 'No'}</span>
                </div>
              </div>
            }
          </>
          :
          <div className="flex items-center" style={{ justifyContent: "space-between" }}>
            <div>
              <i className="las la-dog text-lg mr-2"></i>
              <span className="text-sm text-neutral-500">Animali</span>
            </div>
            <div className="flex flex-col ml-auto">
              <span className="text-sm text-neutral-500">Non ammessi</span>
            </div>
          </div>
        }

        {quota_una_tantum > 0 &&
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Prenotazione</span>  <span>€ {(quota_una_tantum).toFixed(2)}</span>
          </div>
        }
        {area != "" &&
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Area assegnata: </span> <span className="font-bold">{area}</span>
          </div>
        }
        {note_generali != null && note_generali != "" &&
          <>
            <div>
              <FlightCardNote data={{ titolo: title_note_generali, descrizione: note_generali.replace('$TA', ta).replace('$TB', tb), backColor: '#BAA273' }} />
            </div>
          </>
        }
        {note_token != "" && note_token != null &&
          <>
            <div>
              <FlightCardNote data={{ titolo: title_note_token, descrizione: note_token, backColor: '#BAA273' }} />
            </div>
          </>
        }
        {((arrayScelto.stato == 'RICHIESTA' || arrayScelto.stato == 'RICHIESTA_ALL') && ((accordion_richiestadiprenotazione != "" && accordion_richiestadiprenotazione != null) && (testo_accordion_richiestadiprenotazione != "" && testo_accordion_richiestadiprenotazione != null))) &&
          <>
            <div>
              <FlightCardNote data={{ titolo: accordion_richiestadiprenotazione, descrizione: testo_accordion_richiestadiprenotazione, backColor: '#BAA273' }} />
            </div>
          </>
        }
        {(arrayScelto.stato == "ATTESA" && ((accordion_listadiattesa != "" && accordion_listadiattesa != null) && (testo_accordion_listadiattesa != "" && testo_accordion_listadiattesa != null))) &&
          <>
            <div>
              <FlightCardNote data={{ titolo: accordion_listadiattesa, descrizione: testo_accordion_listadiattesa, backColor: '#BAA273' }} />
            </div>
          </>
        }
        {((accordion_bambini != "" && accordion_bambini != null) && (testo_accordion_bambini != "" && testo_accordion_bambini != null)) &&
          <>
            <div>
              <FlightCardNote data={{ titolo: accordion_bambini, descrizione: testo_accordion_bambini, backColor: '#BAA273' }} />
            </div>
          </>
        }
        {(tipologia != "RISTORANTE" && tipologia != "APERITIVO") &&
          <div className="flex justify-between font-semibold">
            <span>Totale</span>
            <span>€ {(parseFloat(tariffaAdulto) * adulti + parseFloat(tariffaBambino) * bambini + quota_una_tantum).toFixed(2)}</span>
          </div>
        }
      </div>
    );
  };

  const sezioneFoto = () => {

    return (

      <div className="listingSection__wrap shadow-xl p-5">
        <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
          <div className="col-span-3 row-span-3 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={() => handleOpenModal(0)}
          >
            <NcImage
              containerClassName="display-block inset-0 w-full h-full"
              className="object-cover w-full rounded-md sm:rounded-xl"
              src={image1}
              prevImageHorizontal
            />
            {/* Opacità al passaggio del mouse sulla foto */}
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            {/* Fine: Opacità al passaggio del mouse sulla foto */}
          </div>

          {PHOTOS.filter((_, i) => i >= 1 && i < 4).map((item, index) => (
            <div
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 2 ? "block" : ""}`}
            >
              <NcImage
                containerClassName="aspect-w-3 aspect-h-2"
                className="object-cover w-full h-full rounded-md sm:rounded-xl "
                src={item || ""}
                prevImageHorizontal
              />

              {/* OVERLAY */}
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={() => handleOpenModal(index + 1)}
              />
            </div>
          ))}
          {/* button vedi foto */}
          <div
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
            onClick={() => handleOpenModal(0)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
              />
            </svg>
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Vedi tutte le foto
            </span>
          </div>
          {/* Fine: button vedi foto */}
        </div>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={PHOTOS}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
        />

        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold text-primary-100">
          {arrayScelto?.title_scheda || titolo_iniziale}
        </h2>

        {/* <div className="w-full border-b border-neutral-100 dark:border-neutral-700" /> */}

        {/* <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-clock text-2xl"></i>
            <span className="">{data_finale}</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-clock text-2xl"></i>
            <span className="">{ora?.slice(0, 5)} - {ora_fine?.slice(0, 5)}</span>
          </div>
        </div> */}
      </div>
    );
  };

  const sezioneDescrizione = () => {
    return (
      <div className="flex flex-col shadow-xl p-5 mt-5">
        <h2 className="text-2xl font-semibold text-primary-100">{arrayScelto?.titolo_services}</h2>
        {arrayScelto?.description_services != "" && arrayScelto?.description_services != null &&
          <div className="py-2 mt-4">
            <FlightCardMirko data={{ titolo: "Descrizione evento", descrizione: arrayScelto?.description_services }} />
          </div>
        }
        {arrayScelto?.subtitle_1 != null && arrayScelto?.des_subtitle_1 != "" &&
          <div className="py-2">
            <FlightCardMirko data={{ titolo: arrayScelto?.subtitle_1, descrizione: arrayScelto?.des_subtitle_1 }} />
          </div>
        }
        {arrayScelto?.subtitle_2 != null && arrayScelto?.des_subtitle_2 != "" &&
          <div className="py-2">
            <FlightCardMirko data={{ titolo: arrayScelto?.subtitle_2, descrizione: arrayScelto?.des_subtitle_2 }} />
          </div>
        }

        {arrayScelto?.subtitle_3 != null && arrayScelto?.des_subtitle_3 != "" &&
          <div className="py-2">
            <FlightCardMirko data={{ titolo: arrayScelto?.subtitle_3, descrizione: arrayScelto?.des_subtitle_3 }} />
          </div>
        }

        {arrayScelto?.subtitle_4 != null && arrayScelto?.des_subtitle_4 != "" &&
          <div className="py-2">
            <FlightCardMirko data={{ titolo: arrayScelto?.subtitle_4, descrizione: arrayScelto?.des_subtitle_4 }} />
          </div>
        }

      </div>
    );
  };

  // desktop
  const gocheckoutdesktop = () => {
    return (
      <div className="listingSection__wrap shadow-xl p-5">
        {(aperto == 1) &&
          <span className="text-center">{(label_titolo != "" && label_titolo != null) ? label_titolo : 'Seleziona la data, la tipologia e il numero di persone prima di continuare'}</span>
        }
        {aperto == 1 &&
          <div className="flex flex-col">
            {renderInputData()}
            {data_finale &&
              <>
                {renderTipologia()}
                {(tipologia != "" && tipologia == "RISTORANTE") &&
                  renderSceltaRistorante()
                }
                {(tipologia != "" && tipologia != "RISTORANTE") &&
                  renderAdulti()
                }
                {tipologia == "RISTORANTE" && tiporistorante != "" &&
                  renderAdulti()
                }
                {adulti > 0 && tipologia != "" &&
                  renderInputBambiniCk()
                }
                {bambinick === true && tipologia != "" &&
                  renderInputBambini()
                }
                {tiporistorante != "" && bambinisel === true && tipologia == "RISTORANTE" &&
                  renderFasceOrarie()
                }
                {(adulti + bambini) > 0 && bambinisel === true &&
                  renderInputAnimali()
                }
              </>
            }
          </div >
        }
        {
          tipologia != "" &&
          goDescription()
        }
        {
          verificatoButton == 0 &&
          buttonVerificaDisp()
        }
        {
          verificatoButton == 1 &&
          checkoutRender()
        }
      </div >
    );
  };

  //mobile
  const gocheckoutmobile = () => {
    return (
      <div className="listingSection__wrap shadow-xl p-5">
        {(aperto == 1) &&
          <span className="text-center">{(label_titolo != "" && label_titolo != null) ? label_titolo : 'Seleziona la data, la tipologia e il numero di persone prima di continuare'}</span>
        }

        {aperto == 1 &&
          <div className="flex flex-col">
            {renderInputData()}
            {data_finale &&
              <>
                {renderTipologia()}
                {tipologia == "RISTORANTE" &&
                  renderSceltaRistorante()
                }
                {(tipologia != "" && tipologia != "RISTORANTE") &&
                  renderAdulti()
                }
                {tipologia == "RISTORANTE" && tiporistorante != "" &&
                  renderAdulti()
                }
                {adulti > 0 && tipologia != "" &&
                  renderInputBambiniCk()
                }
                {bambinick === true && tipologia != "" &&
                  renderInputBambini()
                }
                {tiporistorante != "" && bambinisel === true && tipologia == "RISTORANTE" &&
                  renderFasceOrarie()
                }
                {(adulti + bambini) > 0 && bambinisel === true &&
                  renderInputAnimali()
                }
              </>
            }
          </div >
        }

        {tipologia != "" &&
          goDescription()
        }

        {verificatoButton == 0 &&
          buttonVerificaDisp()
        }

        {verificatoButton == 1 &&
          checkoutRender()
        }
      </div>
    );
  };

  return (
    <div
      className={`nc-ListingExperiencesDetailPage  ${className}`}
      data-nc-id="ListingExperiencesDetailPage"
    >
      <main className="container mt-11 flex ">
        <div className="w-full mb-8 lg:pr-10 lg:space-y-10">
          {sezioneFoto()}
          <div className="lg:hidden block mt-10">
            {gocheckoutmobile()}
          </div>
          {arrayScelto.titolo_services &&
            sezioneDescrizione()
          }
        </div>
        <div className="hidden lg:block">
          {gocheckoutdesktop()}
        </div>
      </main>
    </div>
  );
};

export default ListingExperiencesDetailLodge;
