import React, { FC, useState } from "react";
import Heading2 from "components/Heading/Heading2";
import ExperiencesCardH from "components/ExperiencesCardH/ExperiencesCardH";

export interface SectionGridHasMapProps {
  events:[{id: string}]
  titolo: string;
}

const SectionGridHasMap: FC<SectionGridHasMapProps> = ({events, titolo}) => {

  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1); 

  return (
        <div className="w-full flex-shrink-0 xl:px-8 ">
          <Heading2 className="text-primary-6000" heading={titolo}
            subHeading={
              <span className="block mt-3">
                Disponibili n. {events.length} eventi
              </span>
            }
          />
          <div className="grid grid-cols-1 gap-8">
            {events.length>0 && events.map((item) => (
              <div
                key={item.id}
                onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                onMouseLeave={() => setCurrentHoverID((_) => -1)}
              >
                <ExperiencesCardH {...item}/>
              </div>
            ))}
          </div>
        </div>
  );
};

export default SectionGridHasMap;
