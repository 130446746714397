import React, { FC, useEffect, Fragment, useState } from "react";
import GuestsInputMirko from "components/HeroSearchForm/GuestsInputMirko";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos";
import GuestsInputSolarium from "components/HeroSearchForm/GuestsInputSolarium";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import GuestsInputServizi from "components/HeroSearchForm/GuestsInputServizi";
import FlightCardMirko from "components/FlightCard/FlightCardMirko";
import FlightCardNote from "components/FlightCard/FlightCardNote";
import ExperiencesDateSingleInput from "components/HeroSearchForm/ExperiencesDateSingleInput";
import GuestsInputOptionTipology from "components/HeroSearchForm/GuestsInputOptionTipology";
import GuestsInputBambini from "components/HeroSearchForm/GuestsInputBambini";
import GuestsInputAnimali from "components/HeroSearchForm/GuestsInputAnimali";
import moment from "moment";
import "moment/locale/it";
import GuestsInputBamibiCheck from "components/HeroSearchForm/GuestsInputBambiniCheck";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Select from "shared/Select/Select";
import { useHistory } from "react-router-dom";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import SaleOffBadgeGrey from "components/SaleOffBadge/SaleOffBadgeGrey";
import Button from "shared/Button/Button";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Etichette } from "components/ExperiencesCardH/ExperiencesCardH";

export interface ListingExperiencesDetailPageProps {
  className?: string;
  id?: string;
  titolo?: string;
  des_offerta?: string;
  item?: [];
}

export interface ServizioType {
  nome: string;
  price_adulti: string;
  price_bambino: string;
}

export interface TipologiaServiziType {
  id: string;
  tipologia_id: string;
  nome: string;
  isChecked: boolean;
  prezzo_adulto: string;
  prezzo_bambino: string;
  action: number;
  quantita: number;
}

export interface test {
  id?: string;
  titolo?: string;
  des_offerta?: string;
}

const options = [
  { value: "PRANZO", label: "PRANZO" },
  { value: "CENA", label: "CENA" },
];

export interface State {
  id: string;
}

export interface GuestsInputPropsAdulti {
  guestAdults?: number;
  max_persone?: number;
  min_persone?: number;
}

export interface GuestsInputPropsTeen {
  guestTeen?: number;
  max_persone?: number;
  min_persone?: number;
}

export interface GuestsInputPropsBambini {
  guestChildren?: number;
  guestChildrenBaby?: number;
}

interface GuestsInputPropsBambiniCk {
  guestBambiniCk?: boolean;
  clicked?: boolean;
}

export interface GuestsInputPropsAnimali {
  guestAnimals?: boolean;
}

const ListingExperiencesDetailPage: FC<ListingExperiencesDetailPageProps> = ({ className = "", ...props }) => {
  //Image
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };
  const handleCloseModal = () => setIsOpen(false);

  const [isOpen1, setIsOpen1] = useState(false);
  const [openFocusIndex1, setOpenFocusIndex1] = useState(0);
  const handleOpenModal1 = (index: number) => {
    setIsOpen1(true);
    setOpenFocusIndex1(index);
  };
  const handleCloseModal1 = () => setIsOpen1(false);
  //End image

  const location = useLocation<State>();
  let temp = useParams<{ id: string }>();
  const id_evento = temp.id;

  const [evento, setEvento] = useState<any>([]);
  const [eventiCorrelati, setEventiCorrelati] = useState<any>([]);
  const [typesevento, setTypeEvento] = useState<any>([]);
  const [optionsevento, setTypeOptions] = useState<any>([]);
  const [optionseve, setTypeOptionsEve] = useState<any>([]);
  const [arrayServiziTemp, setArrayServizi] = useState(Array<TipologiaServiziType>());
  const [imagesTmp, setImageTmp] = useState<any>([]);
  const [images, setImage] = useState<any>([]);
  const [imagesAdd, setImageAdd] = useState<any>([]);
  const [data_finale, setDataSelezionata] = useState("");
  const [dateValue, setdateValue] = useState<moment.Moment | null>(null);
  const [dateFocused, setDateFocused] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  //Miei controlli
  const [alertTipologia, setAlertTipologia] = useState(false);
  const [alertAdulti, setAlertAdulti] = useState(false);
  const [alertBambiniChecked, setAlertBambiniChecked] = useState(false);
  const [alertBambini, setAlertBambini] = useState(false);
  const [alertAnimali, setAlertAnimali] = useState(false);
  const [alertMetodoPagamento, setAlertMetodoPagamento] = useState(false);
  const [isOpenModalPrezzi, setIsOpenModalPrezzi] = useState(false);
  const [prezzoShowModal, setPrezzoShowModal] = useState("");
  const [metodoPagamento, setMetodoPagamento] = useState("");

  let history = useHistory();
  useEffect(() => {
    if (dateValue) {
      const data_evento_scelto = dateValue?.format("YYYY-MM-DD");
      const data_finale_tmp = dateValue?.format("DD/MM/YYYY");
      const dataScelta = new Date(data_evento_scelto);
      if (data_start <= dataScelta && data_to >= dataScelta) {
        setDataSelezionata(data_finale_tmp);
      } else {
        MySwal.fire({
          title: <p>Attenzione</p>,
          html: <p>L'evento non è disponibile nella data selezionata!</p>,
          icon: "warning",
          confirmButtonColor: "#aa7832a8",
          didOpen: () => {
            //MySwal.clickConfirm()
          },
        }).then(() => {
          //return MySwal.fire(<p>Shorthand works too</p>)
        });
        setDataSelezionata("");
        // setdateValue(moment(data_start));
      }
    }
  }, [dateValue]);

  const temp_data = evento?.data;

  useEffect(() => {
    if (more_day == 0) {
      let anno = temp_data?.slice(0, 4);
      let mese = temp_data?.slice(5, 7);
      let giorno = temp_data?.slice(8, 10);
      const tmpData = giorno + "/" + mese + "/" + anno;
      setDataSelezionata(tmpData);
    }
  }, [temp_data]);

  // Numero di aulti e bambini
  const [adulti, setAdulti] = useState(0);
  const [bambini, setBambini] = useState(0);
  const [bambiniBaby, setBambiniBaby] = useState(0);
  const [bambinick, setBambiniCk] = useState(false);
  const [bambinisel, setBambiniSel] = useState(false);
  const [animali, setAnimali] = useState(false);
  const [animalick, setAnimaliChecked] = useState(false);
  const [optiontrovato, setTrovatoOption] = useState(0);
  const [tipologia, setTipologia] = useState("");
  const [ta, setTA] = useState("");
  const [tb, setTB] = useState("");
  //Nuovi testi giovanni
  const [text_areaSoldOut, setTextAreaSoldOut] = useState("");
  const [text_tipologiaSoldOut, setTextTipologiaSoldOut] = useState("");
  const [text_utenteAbilitato, setTextUtenteAbilitato] = useState("");
  const [text_richiestaCaparra, setTextRichiestaCaparra] = useState("");
  const [text_statoRichiestaOnline, setTextRichiestaStatoOnline] = useState("");
  const [text_statoRichiestaStruttura, setTextRichiestaStatoStruttura] = useState("");
  const [text_statoAttesaStruttura, setTextAttesaStatoStruttura] = useState("");
  const [text_statoAttesaOnline, setTextAttesaStatoOnline] = useState("");
  const [text_prenotazioneEsistente, setTextPrenotazioneEsistente] = useState("");
  const [text_swalCaparra, setTextSwalCaparra] = useState("");
  const [index, setIndex] = useState(-1);
  const [max_persone, setMaxpersone] = useState(0);
  const [min_persone, setMinpersone] = useState(0);
  const [quota_una_tantum, setUnatantum] = useState(0);
  const [totalePersone, setTotalePersone] = useState(0);
  const [area, setArea] = useState("");
  const [area_id, setAreaId] = useState(0);
  const [tipo_id, setTipoId] = useState(0);
  const [oldstato, setOldState] = useState("");
  //const [servizioTipologia, setServizioTipologia] = useState('');
  const [prezzoServizioTipologiaAdulto, setPrezzoServizioTipologiaAdulto] = useState(0);
  const [prezzoServizioTipologiaBambino, setPrezzoServizioTipologiaBambino] = useState(0);
  const [prezzoServizioTipologiaAdultoToken, setPrezzoServizioTipologiaAdultoToken] = useState(0);
  const [prezzoServizioTipologiaBambinoToken, setPrezzoServizioTipologiaBambinoToken] = useState(0);
  const [costoProdottoOpzione, setCostoProdottoOpzione] = useState(0);
  const [val_pren_inferiore, setValPrenInferiore] = useState(0);
  const [val_pren, SetValPrenSuperiore] = useState(0);
  const [actionOpzioniEvento, setActionOpzioniEvento] = useState(0);
  const [verificatoButton, setVerificatoButton] = useState(0);
  const referente_required = evento?.referente_required;
  const etichette_stati = evento?.etichette_stati;
  const referente = evento?.referente;
  const titolo_booking = evento?.titolo_booking;
  const ora = evento?.ora;
  const ora_fine = evento?.ora_fine;
  const evento_id = id_evento;
  const aperto = evento?.aperto;
  const title_note_token = evento?.title_note_token || "Nota Formula Token";
  const note_token = evento?.note_token;
  const label_stato = evento?.label_stato;
  const accordion_bambini = evento?.accordion_bambini;
  const accordion_richiestadiprenotazione = evento?.accordion_richiestediprenotazione;
  const accordion_listadiattesa = evento?.accordion_listadiattesa;
  const testo_accordion_bambini = evento?.testo_accordion_bambini;
  const testo_accordion_richiestadiprenotazione = evento?.testo_accordion_richiestediprenotazione;
  const testo_accordion_listadiattesa = evento?.testo_accordion_listadiattesa;
  const color_accordion_listadiattesa = evento?.color_accordion_listadiattesa || '"#BAA273"';
  const color_accordion_richiestediprenotazione = evento?.color_accordion_richiestediprenotazione || '"#BAA273"';
  const color_accordion_bambini = evento?.color_accordion_bambini || '"#BAA273"';
  const color_title_note_token = evento?.color_title_note_token || '"#BAA273"';
  const color_accordion_acconto = evento?.color_accordion_acconto || '"#BAA273"';
  const testo_accordion_acconto = evento?.testo_accordion_acconto;
  const open_accordion_note_token = evento?.open_accordion_note_token || "NO";
  const open_accordion_bambini = evento?.open_accordion_bambini || "NO";
  const open_accordion_listadiattesa = evento?.open_accordion_listadiattesa || "NO";
  const open_accordion_richiestediprenotazione = evento?.open_accordion_richiestediprenotazione || "NO";
  const open_accordion_acconto = evento?.open_accordion_acconto || "NO";
  const categoria_evento = evento?.categoria_evento;
  const accordion_acconto = evento?.accordion_acconto;

  const data_start = new Date(evento?.data);
  const data_to = new Date(evento?.data_to);
  const more_day = evento?.more_day;

  const subtitle_1 = evento?.subtitle_1;
  const subtitle_2 = evento?.subtitle_2;
  const subtitle_3 = evento?.subtitle_3;
  const subtitle_4 = evento?.subtitle_4;
  const subtitle_5 = evento?.subtitle_5;
  const des_subtitle_1 = evento?.des_subtitle_1;
  const des_subtitle_2 = evento?.des_subtitle_2;
  const des_subtitle_3 = evento?.des_subtitle_3;
  const des_subtitle_4 = evento?.des_subtitle_4;
  const des_subtitle_5 = evento?.des_subtitle_5;

  var arrayScelto = typesevento[index];
  const paga_online = arrayScelto?.paga_online;
  const paga_struttura = arrayScelto?.paga_struttura;
  const color_note_generali = arrayScelto?.color_note_generali || '"#BAA273"';
  const tariffa_struttura = parseFloat(arrayScelto?.tariffa_struttura);
  const tariffa_struttura_bambino = parseFloat(arrayScelto?.tariffa_struttura_bambino);
  const tariffa_online = parseFloat(arrayScelto?.tariffa_online);
  const tariffa_online_bambino = parseFloat(arrayScelto?.tariffa_online_bambino);
  const title_note_generali = arrayScelto?.title_note_generali || "Note generali";
  const note_generali = arrayScelto?.note_generali;
  const open_accordion_note_generali = arrayScelto?.open_accordion_note_generali || "NO";
  const evento_token = arrayScelto?.evento_token;
  const animali_ammessi = arrayScelto?.animali_ammessi;
  const bambini_ammessi = arrayScelto?.bambini_ammessi;
  const acconto = arrayScelto?.acconto;

  const tipologies = new Array();

  typesevento.map((item: any) => {
    tipologies.push([item.tipo, item.etichetta, item.stato, item.etichette]);
  });

  let anno = data_finale?.slice(6, 10);
  let mese = data_finale?.slice(3, 5);
  let giorno = data_finale?.slice(0, 2);
  const datacomposta = anno + "/" + mese + "/" + giorno;
  const temp_day = new Date(datacomposta).getDay();

  let day = "";

  switch (temp_day) {
    case 0:
      day = "Domenica";

      break;
    case 1:
      day = "Lunedì";

      break;
    case 2:
      day = "Martedì";

      break;
    case 3:
      day = "Mercoledì";

      break;
    case 4:
      day = "Giovedì";

      break;
    case 5:
      day = "Venerdì";

      break;
    case 6:
      day = "Sabato";

      break;
    default:
      break;
  }

  var link = new Array();
  const MySwal = withReactContent(Swal);

  function setPayment(metodo: string) {
    setAlertMetodoPagamento(false);
    if (metodo == "online") {
      setMetodoPagamento(metodo);
    } else if (metodo == "struttura") {
      setMetodoPagamento(metodo);
    }
  }

  function closeModalPrezzi() {
    setIsOpenModalPrezzi(false);
  }

  function openModalPrezzi(tipo: string) {
    setPrezzoShowModal(tipo);
    setIsOpenModalPrezzi(true);
  }

  useEffect(() => {
    if (arrayScelto) {
      setMaxpersone(arrayScelto.max_persone);
      setMinpersone(arrayScelto.min_persone);
      setUnatantum(parseFloat(arrayScelto.quota_una_tantum));
      setTextAreaSoldOut(arrayScelto.text_area_soldout);
      setTextTipologiaSoldOut(arrayScelto.text_tipologia_soldout);
      setTextUtenteAbilitato(arrayScelto.text_utente_abilitato);
      setTextRichiestaCaparra(arrayScelto.text_richiesta_caparra);
      setTextPrenotazioneEsistente(arrayScelto.text_prenotazione_esistente);
      setTextRichiestaStatoOnline(arrayScelto.text_statorichiesta_online);
      setTextRichiestaStatoStruttura(arrayScelto.text_statorichiesta_struttura);
      setTextAttesaStatoOnline(arrayScelto.text_statoattesa_online);
      setTextAttesaStatoStruttura(arrayScelto.text_statoattesa_struttura);
      setTextSwalCaparra(arrayScelto.text_swal_caparra);

      if (
        arrayScelto.val_pren_inferiore_tipologia != "" &&
        arrayScelto.val_pren_inferiore_tipologia != null &&
        arrayScelto.val_pren_inferiore_tipologia != undefined
      )
        setValPrenInferiore(arrayScelto.val_pren_inferiore_tipologia);
      else setValPrenInferiore(evento?.val_pren_inferiore);

      if (arrayScelto.val_pren_tipologia != "" && arrayScelto.val_pren_tipologia != null && arrayScelto.val_pren_tipologia != undefined)
        SetValPrenSuperiore(arrayScelto.val_pren_tipologia);
      else SetValPrenSuperiore(evento?.val_pren);

      getProdottiService();
      setVerificatoButton(0);
    }
  }, [adulti, bambini, bambiniBaby, arrayScelto, animali]);

  useEffect(() => {
    if (!location.state) {
      setAdulti(0);
      setBambini(0);
      setBambiniBaby(0);
      setAnimali(false);
      setTrovatoOption(0);
    }

    const optionseve = new Array();
    if (arrayScelto) {
      if (arrayScelto.options.length > 0) {
        arrayScelto.options.map((item: any) => {
          optionseve.push([item.id, item.tipologia_id, item.nome, item.prezzo_adulto, item.prezzo_bambino, item.action]);

          setTypeOptionsEve(optionseve);
          setTrovatoOption(1);
        });
      }
    }
  }, [arrayScelto]);

  const checkDisponibilita = () => {
    if (!(tipologia != "" && totalePersone >= min_persone && totalePersone <= max_persone && verificatoButton == 0 && animalick == true)) {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Devi specificare tutti i campi (Data, tipo prenotazione,adulti, animali si/no) etc!</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        },
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      });

      if (tipologia == "") {
        setAlertTipologia(true);
        return;
      } else setAlertTipologia(false);

      if (adulti == 0) {
        setAlertAdulti(true);
        return;
      } else setAlertAdulti(false);

      if (bambinisel == false) {
        setAlertBambiniChecked(true);
        return;
      } else setAlertBambiniChecked(false);

      if (bambinick == true && bambini + bambiniBaby == 0) {
        setAlertBambini(true);
        return;
      } else setAlertBambini(false);

      if (animalick == false) {
        setAlertAnimali(true);
        return;
      } else setAlertAnimali(false);

      return;
    }

    if (metodoPagamento == "") {
      //if (paga_online == 1 && metodoPagamento == '') { OLD
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Devi specificare il metodo di pagamento!</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        },
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      });

      if (metodoPagamento == "") {
        // if (paga_online == 1 && metodoPagamento == '') { OLD
        setAlertMetodoPagamento(true);
        return;
      } else setAlertMetodoPagamento(false);

      return;
    }

    setLoading(true);
    fetch(
      `https://booking.jooba.it/check_service?evento_id=${id_evento}&service_id=${arrayScelto.tipo_id}&adulti=${adulti}&bambini=${bambini + bambiniBaby
      }&animali=${animali == true ? 1 : 0}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then(async (response) => {
        setTimeout(() => {
          setVerificatoButton(1);
          if (response.reponse === false) {
            MySwal.fire({
              title: <p>Attenzione</p>,
              html: <p>{text_areaSoldOut}</p>,
              icon: "warning",
              confirmButtonColor: "#aa7832a8",
              footer: "Ricordiamo che in base al tipo e numero di partecipanti viene selezionata un'area di destinazione!",
              didOpen: () => {
                //MySwal.clickConfirm()
              },
            }).then(() => {
              //return MySwal.fire(<p>Shorthand works too</p>)
            });
            setOldState(arrayScelto?.stato);
            setTipoId(arrayScelto?.tipo_id);
            arrayScelto.stato = "ATTESA";
            setArea(response.area);
            setAreaId(response.area_id);
          } else {
            if (tipo_id != 0 && tipo_id == arrayScelto?.tipo_id) {
              arrayScelto.stato = oldstato;
            }
            setArea(response.area);
            setAreaId(response.area_id);
          }
        }, 600);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    const tmp = adulti;

    if (tmp > 0 && min_persone > 0 && max_persone > 0) {
      if (tmp > max_persone) {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: <p>Attenzione</p>,
          html: <p>Numero massimo di partecipanti superato!</p>,
          confirmButtonColor: "#aa7832a8",
          icon: "error",
          //footer: 'Copyright 2018',
          didOpen: () => {
            //MySwal.clickConfirm()
          },
        }).then(() => {
          //return MySwal.fire(<p>Shorthand works too</p>)
        });
      }
    }
    setTotalePersone(tmp);
  }, [adulti, min_persone, max_persone]);

  useEffect(() => {
    if (imagesTmp.length > 0) {
      const updatedImagesTmp = imagesTmp.map((image: any) => {
        if (image.type === 'slideshow') {
          return {
            type: image.type,
            link: `https://private.jooba.it/image/slideshow/${image?.foto}/${id_evento}`
          };
        } else if (image.type === 'slide_add') {
          return {
            type: image.type,
            link: `https://private.jooba.it/image/slide_add/${image?.foto}/${id_evento}`
          };
        } else {
          return image;
        }
      });

      const imgheaderLinks = updatedImagesTmp.filter((image: any) => image.type === 'slideshow');
      const imgdesLinks = updatedImagesTmp.filter((image: any) => image.type === 'slide_add');

      setImage(imgheaderLinks);
      setImageAdd(imgdesLinks);
    }

  }, [imagesTmp]);

  useEffect(() => {
    if (categoria_evento != undefined) getCategoriaEvento();
  }, [categoria_evento]);

  useEffect(() => {
    getEvents();
    getImageEvent();
    getTypeEvents();
  }, []);

  const getCategoriaEvento = () => {
    fetch(`https://booking.jooba.it/getCategoriaEvento?categoria_evento=${categoria_evento}&id=${evento_id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(async (response) => {
        setEventiCorrelati(response);
      })
      .catch((error) => console.log(error));
  };

  const getEvents = () => {
    fetch(`https://booking.jooba.it/events`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: evento_id,
      }),
    })
      .then((response) => response.json())
      .then(async (response) => {
        setEvento(response[0]);
      })
      .catch((error) => console.log(error));
  };

  const getTypeEvents = () => {
    fetch(`https://booking.jooba.it/types?id=${id_evento}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(async (response) => {
        response.data.forEach((item: any) => {
          if (item.options.length > 0) setTypeOptions(item.options);
        });
        setTypeEvento(response.data);
        setTA(response.ta);
        setTB(response.tb);
      })
      .catch((error) => console.log(error));
  };

  const getImageEvent = () => {
    fetch(`https://booking.jooba.it/eventimages?id=${id_evento}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(async (response) => {
        setImageTmp(response);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (location.state) {
      if (typesevento.length > 0) {
        let state: any = null;
        state = location.state;

        if (state?.info?.tipologia?.tipologia_id != undefined) {
          const indexDesiderato = typesevento.findIndex((e: any) => e.id === state.info.tipologia.tipologia_id);

          setLoading(false);
          setVerificatoButton(0);
          setIndex(indexDesiderato);
          setAlertTipologia(false);
          arrayScelto = typesevento[indexDesiderato];
          setTipologia(arrayScelto.tipo);

          if (paga_online == 1 && paga_struttura == 0) {
            setMetodoPagamento("online");
          }

          if (paga_online == 0 && paga_struttura == 1) {
            // OLD -> blocco aggiunto
            setMetodoPagamento("struttura");
          }

          setLoading(false);
          setVerificatoButton(0);

          setAdulti(state.info.adulti);
          setBambini(state.info.bambini);
          setBambiniBaby(state.info.bambiniBaby);
          setAnimali(state.info.animali);

          setBambiniSel(true);
          bambini + bambiniBaby > 0 ? setBambiniCk(true) : setBambiniCk(false);
          setAnimaliChecked(true);

          setAlertAdulti(false);
          setAlertBambiniChecked(false);
          setAlertBambini(false);
          setAlertAnimali(false);
        }
      }
    }
  }, [typesevento]);

  function handleSelectChange(event: any) {
    history.push(`/dettaglio/${event.target.value}`);
    window.location.reload();
  }

  const handlerAdulti = (person: GuestsInputPropsAdulti) => {
    if (index >= 0) {
      if (paga_online == 1 && paga_struttura == 0) {
        setMetodoPagamento("online");
      }

      if (paga_online == 0 && paga_struttura == 1) {
        // OLD -> blocco aggiunto
        setMetodoPagamento("struttura");
      }
      setLoading(false);
      setVerificatoButton(0);
      setAdulti(person.guestAdults || adulti);
      setAlertAdulti(false);
    }
  };

  const handlerBambiniCk = (person: GuestsInputPropsBambiniCk) => {
    if (index >= 0) {
      if (bambini_ammessi == 1) {
        setLoading(false);
        setVerificatoButton(0);
        setBambiniCk(person.guestBambiniCk || bambinick);
        if (person.clicked === true) {
          setBambiniSel(true);
        }

        setBambini(0);
        setBambiniBaby(0);
        setAlertBambiniChecked(false);
      } else {
        setBambiniSel(true);
        setBambiniCk(person.guestBambiniCk || false);
      }
    }
  };

  const handlerBambini = (person: GuestsInputPropsBambini) => {
    if (index >= 0) {
      setLoading(false);
      setVerificatoButton(0);
      setBambini(person.guestChildren || bambini);
      setBambiniBaby(person.guestChildrenBaby || bambiniBaby);
      setAlertBambini(false);
    }
  };

  const handlerAnimali = (person: GuestsInputPropsAnimali) => {
    if (index >= 0) {
      if (animali_ammessi == 1) {
        setMetodoPagamento("");

        if (paga_online == 1 && paga_struttura == 0) {
          setMetodoPagamento("online");
        }

        if (paga_online == 0 && paga_struttura == 1) {
          // OLD -> blocco aggiunto
          setMetodoPagamento("struttura");
        }

        setLoading(false);
        setVerificatoButton(0);
        setAnimali(person.guestAnimals || false);
        setAnimaliChecked(true);
        setAlertAnimali(false);
      } else setAnimaliChecked(true);
    }
  };

  const tipoHandler = (tipo: string, index: number) => {
    if (index >= 0) {
      setLoading(false);
      setVerificatoButton(0);
      setIndex(index);
      setTipologia(tipo);
      setAlertTipologia(false);
    }
  };

  const tipoHandlerOption = (arrayServizi: Array<TipologiaServiziType>) => {
    setLoading(false);
    setVerificatoButton(0);
    // setServizioTipologia(tipo);

    var prezzoAdultoServizio_temp = 0;
    var prezzoBambinoServizio_temp = 0;
    var prezzoAdultoServizioToken_temp = 0;
    var prezzoBambinoServizioToken_temp = 0;
    var prezzoCostoProdotto = 0;

    arrayServizi.map((item: TipologiaServiziType) => {
      if (item.isChecked == true) {
        setArrayServizi([]);
        setArrayServizi([item]);
        setActionOpzioniEvento(item.action);

        if (item.action == 1) {
          prezzoAdultoServizio_temp = parseFloat(item.prezzo_adulto) * item.quantita;
          prezzoBambinoServizio_temp = parseFloat(item.prezzo_bambino) * item.quantita;
        } else if (item.action == 2) {
          prezzoAdultoServizioToken_temp = parseFloat(item.prezzo_adulto) * item.quantita;
          prezzoBambinoServizioToken_temp = parseFloat(item.prezzo_bambino) * item.quantita;
        } else {
          prezzoCostoProdotto += parseFloat(item.prezzo_adulto) * item.quantita;
        }
      } else {
        const i = arrayServiziTemp.filter((el) => el.id === item.id);
        if (i.length > 0) {
          setArrayServizi(arrayServiziTemp.filter((el) => el.id !== item.id));
        }
      }
    });

    setPrezzoServizioTipologiaAdulto(prezzoAdultoServizio_temp);
    setPrezzoServizioTipologiaBambino(prezzoBambinoServizio_temp);
    setPrezzoServizioTipologiaAdultoToken(prezzoAdultoServizioToken_temp);
    setPrezzoServizioTipologiaBambinoToken(prezzoBambinoServizioToken_temp);
    setCostoProdottoOpzione(prezzoCostoProdotto);
  };
  const [serviziSelected, setCheckboxItem] = useState(Array<ServizioType>());
  const getProdottiService = () => {
    fetch(`https://booking.jooba.it/getProdottiService?evento_id=${evento_id}&tipo_id=${arrayScelto.tipo_id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(async (response) => {
        let servizio = new Array<ServizioType>();

        servizio.push({ nome: response[0].nome, price_adulti: response[0].tariffa_adulto, price_bambino: response[0].tariffa_bambino });
        setCheckboxItem(servizio);

        setTotAdultiServ(response[0].tariffa_adulto);
        setTotBambiniServ(response[0].tariffa_bambino);
      })
      .catch((error) => console.log(error));
  };


  const [totale_adulti_servizi, setTotAdultiServ] = useState(0);
  const [totale_bambini_servizi, setTotBambiniServ] = useState(0);

  const renderSidebar = () => {
    return (
      <div className="listingSection__wrap shadow-xl p-5" style={{ width: "370px" }}>
        {badgeEvent()}

        {(aperto == 1 || aperto == 5) && (
          <>
            <div className="flex flex-col">
              {renderTipologia()}
              {tipologia != "" && (
                <>
                  {more_day > 0 && renderInputDataDesk()}
                  {data_finale != "" && renderInputAdulti()}
                  {adulti > 0 && renderInputBambiniCk()}
                  {bambinick === true && renderInputBambini()}
                </>
              )}
              {adulti + bambini + bambiniBaby > 0 && renderInputAnimali()}

              {animalick && adulti + bambini + bambiniBaby > 0 && (
                <div className="pt-4">
                  <hr />
                  <div className="flex flex-col text-center text-neutral-6000 pt-4">
                    {arrayScelto.stato == "RICHIESTA" && <span className="mb-4">Stai effettuando una richiesta di prenotazione per: </span>}
                    {arrayScelto.stato == "APERTO" && <span className="mb-4">Stai effettuando una prenotazione per: </span>}
                    <p className="font-bold">{arrayScelto.etichetta != "" ? arrayScelto.etichetta : arrayScelto.tipo}</p>
                    {typesevento[index]?.note_orario === null ? (
                      <span className="font-bold">
                        {day} {data_finale}
                      </span>
                    ) : (
                      <span className="font-bold">
                        {day} {data_finale} - {typesevento[index]?.note_orario}
                      </span>
                    )}
                  </div>
                  <div className={`mt-4 p-1 ${alertMetodoPagamento ? "border border-red-500 rounded-xl" : ""}`}>
                    <div className="text-center">
                      {alertMetodoPagamento && (
                        <SaleOffBadgeGrey
                          colore="bg-red-500 text-white"
                          desc="Scegli uno dei due metodi di pagamento"
                          className="-mt-3 py-0.5"
                        ></SaleOffBadgeGrey>
                      )}
                      {metodoPagamento == "" && !alertMetodoPagamento && (
                        <span className="text-sm" style={{ color: "red" }}>
                          Scegli una modalità di pagamento/tariffa
                        </span>
                      )}
                      {(metodoPagamento != "" && (paga_online == 1 && paga_struttura == 1)) && <span className="text-sm">Metodo di pagamento selezionato: {metodoPagamento}</span>}
                    </div>
                    {/* OLD -> IN ENTRAMBI ERA SOLO PAGA_ONLINE == 1 */}
                    {paga_online == 1 && paga_struttura == 1 && renderInputMetodoDiPagamentoOnline()}
                    {paga_online == 1 && paga_struttura == 1 && renderInputMetodoDiPagamentoStruttura()}
                  </div>
                </div>
              )}
              {optiontrovato == 1 && animalick && (
                <div className="flex-1 border divide-x divide-neutral-200 border-primary-100 rounded-md mt-4">
                  <div className="flex flex-row">
                    <GuestsInputOptionTipology fieldClassName="p-5" onChange={tipoHandlerOption} options={optionseve} />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {verificatoButton == 0 && buttonVerificaDisp()}
        {verificatoButton == 1 && buttonCheckout()}
      </div>
    );
  };

  const renderModalResumeAcquisto = () => {
    return (
      <Transition appear show={isOpenModalPrezzi} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModalPrezzi}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center mb-5">
                    <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                      Riassunto prezzi
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalPrezzi} />
                    </span>
                  </div>
                  {prezzoShowModal == "online" ? renderRiassuntoPrezziOnline() : renderRiassuntoPrezziStruttura()}
                  {renderAccordion()}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderRiassuntoPrezziOnline = () => {
    return (
      <div className="px-5 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
        {adulti > 0 && (
          <div className="mb-10">
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="font-bold">{evento_token == 1 ? "Tariffa adulti" : "Adulti"}</span>{" "}
              <span>
                € {(tariffa_online + prezzoServizioTipologiaAdulto).toFixed(2)} x{adulti}
              </span>
            </div>
            {evento_token == 1 && arrayServiziTemp.length == 0 && (
              <>
                <span className="text-sm underline text-neutral-6000">di cui:</span>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                  <span className="ml-4">Spesa minima food & beverage</span>{" "}
                  <span>
                    € {(tariffa_online - totale_adulti_servizi + prezzoServizioTipologiaAdulto).toFixed(2)} x{adulti}
                  </span>
                </div>
              </>
            )}
            {serviziSelected.map((item) => {
              if (item.price_adulti != "") {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                    <span className="ml-4" style={{ color: "green", textTransform: "capitalize" }}>
                      {item.nome}
                    </span>{" "}
                    <span style={{ color: "green" }}>
                      € {item.price_adulti} x{adulti}
                    </span>
                  </div>
                );
              }
            })}
            {arrayServiziTemp.map((item: TipologiaServiziType) => {
              if (evento_token == 1) {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                    <span className="ml-4" style={{ color: "green" }}>
                      {"Spesa minima food & beverage"}
                    </span>{" "}
                    <span style={{ color: "green" }}>
                      € {(tariffa_online - totale_adulti_servizi - prezzoServizioTipologiaAdultoToken).toFixed(2)} x{adulti}
                    </span>
                  </div>
                );
              }
            })}
          </div>
        )}

        {bambini > 0 && (
          <div className="mb-10">
            <hr />
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="font-bold">{evento_token == 1 ? "Tariffa per bambini" : "Bambini"}</span>{" "}
              <span>
                € {(tariffa_online_bambino + prezzoServizioTipologiaBambino).toFixed(2)} x{bambini}
              </span>
            </div>
            {evento_token == 1 && arrayServiziTemp.length == 0 && (
              <>
                <span className="text-sm underline text-muted">di cui:</span>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                  <span className="ml-4">Spesa minima food & beverage</span>{" "}
                  <span>
                    € {(tariffa_online_bambino - totale_bambini_servizi + prezzoServizioTipologiaBambino).toFixed(2)} x{bambini}
                  </span>
                </div>
              </>
            )}
            {serviziSelected.map((item) => {
              if (item.price_bambino != "") {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                    <span className="ml-4" style={{ color: "green", textTransform: "capitalize" }}>
                    {item.nome == 'LETTINO' ? 'INGRESSO' : item.nome}
                    </span>{" "}
                    <span style={{ color: "green" }}>
                      € {parseFloat(item.price_bambino).toFixed(2)} x{bambini}
                    </span>
                  </div>
                );
              }
            })}
            {arrayServiziTemp.map((item: TipologiaServiziType) => {
              if (evento_token == 1) {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                    <span className="ml-4" style={{ color: "green" }}>{`Spesa minima food & beverage`}</span>{" "}
                    <span style={{ color: "green" }}>
                      € {(tariffa_online_bambino - totale_bambini_servizi - prezzoServizioTipologiaBambinoToken).toFixed(2)} x{bambini}
                    </span>
                  </div>
                );
              }
            })}
          </div>
        )}

        {bambiniBaby > 0 && (
          <div className="mb-10">
            <hr />
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="font-bold">Bambini inferiori a 3 anni</span> <span>Gratis x{bambiniBaby}</span>
            </div>
          </div>
        )}
        {renderRiassuntoAltroCarrello()}
      </div>
    );
  };

  const renderRiassuntoPrezziStruttura = () => {
    return (
      <div className="px-5 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
        {adulti > 0 && (
          <div className="mb-10">
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="font-bold">{evento_token == 1 ? "Tariffa per adulti" : "Adulti"}</span>{" "}
              <span>
                € {(tariffa_struttura + prezzoServizioTipologiaAdulto).toFixed(2)} x{adulti}
              </span>
            </div>
            {evento_token == 1 && arrayServiziTemp.length == 0 && (
              <>
                <span className="text-sm underline text-neutral-6000">di cui:</span>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                  <span className="ml-4">Spesa minima food & beverage</span>{" "}
                  <span>
                    € {(tariffa_struttura - totale_adulti_servizi + prezzoServizioTipologiaAdulto).toFixed(2)} x{adulti}
                  </span>
                </div>
              </>
            )}
            {serviziSelected.map((item) => {
              if (item.price_adulti != "") {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                    <span className="ml-4" style={{ color: "green", textTransform: "capitalize" }}>
                      {item.nome}
                    </span>{" "}
                    <span style={{ color: "green" }}>
                      € {item.price_adulti} x{adulti}
                    </span>
                  </div>
                );
              }
            })}
            {arrayServiziTemp.map((item: TipologiaServiziType) => {
              if (evento_token == 1) {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                    <span className="ml-4" style={{ color: "green" }}>
                      {"Spesa minima food & beverage"}
                    </span>{" "}
                    <span style={{ color: "green" }}>
                      € {(tariffa_struttura - totale_adulti_servizi - prezzoServizioTipologiaAdultoToken).toFixed(2)} x{adulti}
                    </span>
                  </div>
                );
              }
            })}
          </div>
        )}

        {bambini > 0 && (
          <div className="mb-10">
            <hr />
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="font-bold">{evento_token == 1 ? "Tariffa per bambini" : "Bambini"}</span>{" "}
              <span>
                € {(tariffa_struttura_bambino + prezzoServizioTipologiaBambino).toFixed(2)} x{bambini}
              </span>
            </div>
            {evento_token == 1 && arrayServiziTemp.length == 0 && (
              <>
                <span className="text-sm underline text-muted">di cui:</span>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                  <span className="ml-4">Spesa minima food & beverage</span>{" "}
                  <span>
                    € {(tariffa_struttura_bambino - totale_bambini_servizi + prezzoServizioTipologiaBambino).toFixed(2)} x{bambini}
                  </span>
                </div>
              </>
            )}
            {serviziSelected.map((item) => {
              if (item.price_bambino != "") {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                    <span className="ml-4" style={{ color: "green", textTransform: "capitalize" }}>
                      {item.nome == 'LETTINO' ? 'INGRESSO' : item.nome}
                    </span>{" "}
                    <span style={{ color: "green" }}>
                      € {parseFloat(item.price_bambino).toFixed(2)} x{bambini}
                    </span>
                  </div>
                );
              }
            })}
            {arrayServiziTemp.map((item: TipologiaServiziType) => {
              if (evento_token == 1) {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                    <span className="ml-4" style={{ color: "green" }}>{`Spesa minima food & beverage`}</span>{" "}
                    <span style={{ color: "green" }}>
                      € {(tariffa_struttura_bambino - totale_bambini_servizi - prezzoServizioTipologiaBambinoToken).toFixed(2)} x{bambini}
                    </span>
                  </div>
                );
              }
            })}
          </div>
        )}

        {bambiniBaby > 0 && (
          <div className="mb-10">
            <hr />
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="font-bold">Bambini inferiori a 3 anni</span> <span>Gratis x{bambiniBaby}</span>
            </div>
          </div>
        )}

        {renderRiassuntoAltroCarrello()}
      </div>
    );
  };

  const renderRiassuntoAltroCarrello = () => {
    return (
      <div className="space-y-2">
        {arrayServiziTemp.length > 0 && (
          <>
            <div className="flex flex-col justify-between text-neutral-6000 pb-4">
              <span className="flex font-bold">Servizi aggiuntivi selezionati</span>
              {arrayServiziTemp.map((item: TipologiaServiziType) => {
                return (
                  <div className="flex flex-row justify-between">
                    <span className="ml-4 text-sm">{item.nome}</span>{" "}
                    <span className="text-sm">
                      x{item.quantita} € {parseFloat(item.prezzo_adulto).toFixed(2)}
                    </span>
                  </div>
                );
              })}
            </div>
            <hr />
          </>
        )}
        {animalick == true && (
          <>
            <div className="flex justify-between text-neutral-6000 pb-4">
              <span className="flex font-bold">Animali</span> <span>{animali ? "Si" : "No"}</span>
            </div>
            <hr />
          </>
        )}
        {quota_una_tantum > 0 && (
          <>
            <div className="flex justify-between text-neutral-6000 pb-4">
              <span className="flex font-bold">Prenotazione</span> <span>€ {quota_una_tantum.toFixed(2)}</span>
            </div>
            <hr />
          </>
        )}

        {area != "" && (
          <>
            <div className="flex justify-between text-neutral-6000 pb-4">
              <span className="flex font-bold">Area assegnata</span> <span className="font-bold">{area}</span>
            </div>
            <hr />
          </>
        )}
      </div>
    );
  };

  const renderSidebar1 = () => {
    return (
      <div className="listingSection__wrap shadow-xl p-5">
        {badgeEvent()}

        {aperto == 1 && (
          <>
            <div className="flex flex-col">
              {renderTipologia()}

              {tipologia != "" && (
                <>
                  {more_day > 0 && renderInputDataDesk()}
                  {data_finale != "" && renderInputAdulti()}
                  {adulti > 0 && renderInputBambiniCk()}
                  {bambinick === true && renderInputBambini()}
                </>
              )}
              {adulti + bambini + bambiniBaby > 0 && renderInputAnimali()}

              {/* animalick && (adulti + bambini + bambiniBaby) > 0 && paga_online == 1 && OLD */}
              {animalick && adulti + bambini + bambiniBaby > 0 && (
                <div className="pt-4">
                  <hr />
                  <div className="flex flex-col text-center text-neutral-6000 pt-4">
                    {arrayScelto.stato == "RICHIESTA" && <span className="mb-4">Stai effettuando una richiesta di prenotazione per: </span>}
                    {arrayScelto.stato == "APERTO" && <span className="mb-4">Stai effettuando una prenotazione per: </span>}
                    <span className="font-bold">{arrayScelto.etichetta != "" ? arrayScelto.etichetta : arrayScelto.tipo}</span>
                    {typesevento[index]?.note_orario === null ? (
                      <span className="font-bold">
                        {day} {data_finale}
                      </span>
                    ) : (
                      <span className="font-bold">
                        {day} {data_finale} - {typesevento[index]?.note_orario}
                      </span>
                    )}
                  </div>
                  <div className={`mt-4 p-1 ${alertMetodoPagamento ? "border border-red-500 rounded-xl" : ""}`}>
                    <div className="text-center">
                      {alertMetodoPagamento && (
                        <SaleOffBadgeGrey
                          colore="bg-red-500 text-white"
                          desc="Scegli uno dei due metodi di pagamento"
                          className="-mt-3 py-0.5"
                        ></SaleOffBadgeGrey>
                      )}
                      {metodoPagamento == "" && !alertMetodoPagamento && (
                        <span className="text-sm" style={{ color: "red" }}>
                          Scegli una modalità di pagamento/tariffa
                        </span>
                      )}
                      {(metodoPagamento != "" && (paga_online == 1 && paga_struttura == 1)) && <span className="text-sm">Metodo di pagamento selezionato: {metodoPagamento}</span>}
                    </div>
                    {paga_online == 1 && paga_struttura == 1 && renderInputMetodoDiPagamentoOnline()}
                    {paga_online == 1 && paga_struttura == 1 && renderInputMetodoDiPagamentoStruttura()}
                  </div>
                </div>
              )}

              {optiontrovato == 1 && animalick && (
                <div className="flex-1 border divide-x divide-neutral-200 border-primary-100 rounded-md mt-4">
                  <div className="flex flex-row">
                    <GuestsInputOptionTipology fieldClassName="p-5" onChange={tipoHandlerOption} options={optionseve} />
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {verificatoButton == 0 && buttonVerificaDisp()}
        {verificatoButton == 1 && buttonCheckout()}
        {/* PRANZO MOBILE */}
        {/* {tipologia != "" &&
          resumeAcquisto()
        } */}
      </div>
    );
  };

  const renderTipologia = () => {
    let classNameNormale = "flex-1 border divide-x divide-neutral-200 border-primary-100 rounded-md mt-4";
    let classNameErrore = "flex-1 border border-2 divide-x divide-neutral-200 border-red-500 rounded-md mt-4";
    let classNameNew = "";

    if (alertTipologia) {
      classNameNew = classNameErrore;
    } else classNameNew = classNameNormale;

    return (
      <div className={classNameNew}>
        {alertTipologia && (
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-red-500 text-white mr-4" desc="Necessario per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        )}
        {tipologia == "" && !alertTipologia && (
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-primary-100 text-white mr-4" desc="Seleziona per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        )}
        <GuestsInputMirko fieldClassName="p-5 -mt-3" onChange={tipoHandler} tipologies={tipologies} indexDef={index} />
      </div>
    );
  };

  const renderInputAdulti = () => {
    let classNameNormale = "flex-1 border divide-x divide-neutral-200 border-primary-100 rounded-md mt-4";
    let classNameErrore = "flex-1 border border-2 divide-x divide-neutral-200 border-red-500 rounded-md mt-4";
    let classNameNew = "";

    if (alertAdulti) {
      classNameNew = classNameErrore;
    } else classNameNew = classNameNormale;

    return (
      <div className={classNameNew}>
        {alertAdulti && (
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-red-500 text-white mr-4" desc="Necessario per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        )}
        {adulti == 0 && !alertAdulti && (
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-primary-100 text-white mr-4" desc="Seleziona per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        )}
        <GuestsInputSolarium
          fieldClassName="p-5 -mt-3"
          defaultValue={{
            guestAdults: adulti,
            max_persone: max_persone,
            min_persone: min_persone,
          }}
          onChange={handlerAdulti}
        />
      </div>
    );
  };

  const renderInputDataDesk = () => {
    let classNameNormale =
      "flex-1 border divide-x divide-neutral-200 dark:divide-neutral-700 border-neutral-200 dark:border-neutral-700 rounded-md mt-4";
    let classNameErrore =
      "flex-1 border border-2 divide-x divide-neutral-200 dark:divide-neutral-700 border-red-700 dark:border-neutral-700 rounded-md mt-4";
    let classNameNew = "";
    if (data_finale == "") {
      classNameNew = classNameErrore;
    } else classNameNew = classNameNormale;
    return (
      <>
        <div className={classNameNew}>
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-red-500 text-white" desc="Necessario per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
          <ExperiencesDateSingleInput
            defaultValue={dateValue}
            onChange={(date) => {
              setdateValue(date);
              setDateFocused(false);
            }}
            defaultFocus={dateFocused}
            onFocusChange={(focus: boolean) => {
              setDateFocused(true);
            }}
            fieldClassName="p-5 -mt-3"
          />
        </div>
        <span className="mt-2 text-center" style={{ fontSize: "12px" }}>
          L'evento avrà inizio il {data_start.toLocaleDateString()} e terminerà il {data_to.toLocaleDateString()}
        </span>
      </>
    );
  };

  const renderInputBambiniCk = () => {
    let classNameNormale = "flex-1 border divide-x divide-neutral-200 border-primary-100 rounded-md mt-4";
    let classNameErrore = "flex-1 border border-2 divide-x divide-neutral-200 border-red-500 rounded-md mt-4";
    let classNameNew = "";

    if (alertBambiniChecked) {
      classNameNew = classNameErrore;
    } else classNameNew = classNameNormale;

    return (
      <div className={classNameNew}>
        {alertBambiniChecked && (
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-red-500 text-white mr-4" desc="Necessario per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        )}
        {bambinisel == false && !alertBambiniChecked && (
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-primary-100 text-white mr-4" desc="Seleziona per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        )}
        <GuestsInputBamibiCheck
          fieldClassName="p-5 -mt-3"
          defaultValue={{
            bambini_ammessi: bambini_ammessi,
            guestBambiniCk: bambinick,
            clicked: false,
          }}
          onChange={handlerBambiniCk}
        />
      </div>
    );
  };

  const renderInputBambini = () => {
    let classNameNormale = "flex-1 border divide-x divide-neutral-200 border-primary-100 rounded-md mt-4";
    let classNameErrore = "flex-1 border border-2 divide-x divide-neutral-200 border-red-500 rounded-md mt-4";
    let classNameNew = "";

    if (alertBambini) {
      classNameNew = classNameErrore;
    } else classNameNew = classNameNormale;

    return (
      <div className={classNameNew}>
        {alertBambini && (
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-red-500 text-white mr-4" desc="Necessario per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        )}
        {bambini + bambiniBaby == 0 && !alertBambini && (
          <div className="flex flex-row">
            <SaleOffBadgeGrey colore="bg-primary-100 text-white mr-4" desc="Seleziona per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
          </div>
        )}
        <GuestsInputBambini
          fieldClassName="p-5 -mt-3"
          defaultValue={{
            guestChildren: bambini,
            guestChildrenBaby: bambiniBaby,
          }}
          onChange={handlerBambini}
        />
      </div>
    );
  };

  const renderInputAnimali = () => {
    let classNameNormale = "flex-1 border divide-x divide-neutral-200 border-primary-100 rounded-md mt-4";
    let classNameErrore = "flex-1 border border-2 divide-x divide-neutral-200 border-red-500 rounded-md mt-4";
    let classNameNew = "";

    if (alertAnimali) {
      classNameNew = classNameErrore;
    } else classNameNew = classNameNormale;

    if (bambinisel === true) {
      if (bambinick === false) {
        return (
          <div className={classNameNew}>
            {alertAnimali && (
              <div className="flex flex-row">
                <SaleOffBadgeGrey colore="bg-red-500 text-white mr-4" desc="Necessario per continuare" className="-mt-3 ml-auto py-0.5"></SaleOffBadgeGrey>
              </div>
            )}
            {animalick == false && !alertAnimali && (
              <div className="flex flex-row">
                <SaleOffBadgeGrey
                  colore="bg-primary-100 text-white mr-4"
                  desc="Seleziona per continuare"
                  className="-mt-3 ml-auto py-0.5"
                ></SaleOffBadgeGrey>
              </div>
            )}
            <GuestsInputAnimali
              fieldClassName="p-5 -mt-3"
              defaultValue={{
                guestAnimals: animali,
                bambini: bambini,
                bambiniBaby: bambiniBaby,
                animali_ammessi: animali_ammessi,
              }}
              onChange={handlerAnimali}
            />
          </div>
        );
      } else if (bambinick === true && bambini + bambiniBaby > 0) {
        return (
          <div className={classNameNew}>
            {alertAnimali && (
              <div className="flex flex-row">
                <SaleOffBadgeGrey colore="bg-red-500 text-white mr-4" desc="Necessario per continuare" className="-mt-3 ml-auto"></SaleOffBadgeGrey>
              </div>
            )}
            {animalick == false && !alertAnimali && (
              <div className="flex flex-row">
                <SaleOffBadgeGrey
                  colore="bg-primary-100 text-white mr-4"
                  desc="Seleziona per continuare"
                  className="-mt-3 ml-auto py-0.5"
                ></SaleOffBadgeGrey>
              </div>
            )}
            <GuestsInputAnimali
              fieldClassName="p-5 -mt-3"
              defaultValue={{
                guestAnimals: animali,
                bambini: bambini,
                bambiniBaby: bambiniBaby,
                animali_ammessi: animali_ammessi,
              }}
              onChange={handlerAnimali}
            />
          </div>
        );
      }
    }
  };

  const renderInputMetodoDiPagamentoOnline = () => {
    return (
      <>
        <div
          className={`flex-1 border border-4 divide-x divide-neutral-200 dark:border-neutral-700 rounded-md mt-6 ${metodoPagamento == "online" ? "border-primary-100" : "border-gray-300"
            }`}
        >
          <div
            onClick={() => {
              setPayment("online");
            }}
            className="flex flex-col p-2"
          >
            <div className="flex flex-row" style={{ justifyContent: "space-between" }}>
              <a
                onClick={() => {
                  setPayment("online");
                }}
                className={"ml-auto"}
              >
                <SaleOffBadge icon={true} desc="Pagamento online" className="-mt-6 py-1"></SaleOffBadge>
              </a>
            </div>
            <div className="flex flex-row mt-2 mb-2" style={{ alignItems: "center", justifyContent: "space-between" }}>
              <div className="flex items-center space-x-2">
                <i className="las la-male text-lg"></i>
                <div className="flex flex-col">
                  <span className="text-sm text-neutral-500 dark:text-neutral-400">€ {tariffa_online.toFixed(2)}</span>
                  <span className="text-xs text-neutral-500 dark:text-neutral-400" style={{ fontStyle: "italic" }}>
                    Per adulto
                  </span>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <i className="las la-child text-lg"></i>
                <div className="flex flex-col">
                  <span className="text-sm text-neutral-500 dark:text-neutral-400">€ {tariffa_online_bambino.toFixed(2)}</span>
                  <span className="text-xs text-neutral-500 dark:text-neutral-400" style={{ fontStyle: "italic" }}>
                    Per bambino
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-2">
              <div className="flex flex-row" style={{ alignItems: "center" }}>
                <div className="flex-1 space-x-2">
                  <i className="las la-check text-xl" style={{ color: "green" }}></i>
                  <span className="text-sm">Salta la fila</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row pb-2" style={{ alignItems: "center", justifyContent: "center" }}>
            <button
              onClick={() => {
                openModalPrezzi("online");
              }}
            >
              <span style={{ textDecoration: "underline" }} className={"text-sm text-primary-500"}>
                Dettaglio prezzi<i style={{ textDecoration: "underline" }} className="las la-plus"></i>
              </span>
            </button>
          </div>
        </div>
      </>
    );
  };

  const renderInputMetodoDiPagamentoStruttura = () => {
    return (
      <div
        className={`flex-1 border border-4 divide-x divide-neutral-200 dark:border-neutral-700 rounded-md mt-6 ${metodoPagamento == "struttura" ? "border-primary-100" : "border-gray-300"
          }`}
      >
        <div
          onClick={() => {
            setPayment("struttura");
          }}
          className="flex flex-col p-2"
        >
          <div className="flex flex-row" style={{ justifyContent: "space-between" }}>
            <a
              onClick={() => {
                setPayment("struttura");
              }}
              className={"ml-auto"}
            >
              <SaleOffBadgeGrey icon={true} desc="Pagamento in struttura" className="-mt-6 py-1"></SaleOffBadgeGrey>
            </a>
          </div>
          <div className="flex flex-row mt-2 mb-2" style={{ alignItems: "center", justifyContent: "space-between" }}>
            <div className="flex items-center space-x-2">
              <i className="las la-male text-lg"></i>
              <div className="flex flex-col">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">€ {tariffa_struttura.toFixed(2)}</span>
                <span className="text-xs text-neutral-500 dark:text-neutral-400" style={{ fontStyle: "italic" }}>
                  Per adulto
                </span>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <i className="las la-child text-lg"></i>
              <div className="flex flex-col">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">€ {tariffa_struttura_bambino.toFixed(2)}</span>
                <span className="text-xs text-neutral-500 dark:text-neutral-400" style={{ fontStyle: "italic" }}>
                  Per bambino
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-2">
            <div className="flex flex-row" style={{ alignItems: "center" }}>
              <div className="flex-1 space-x-2">
                <i className="las la-times text-xl" style={{ color: "red" }}></i>
                <span className="text-sm">Salta la fila</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row pb-2" style={{ alignItems: "center", justifyContent: "center" }}>
          <button
            onClick={() => {
              openModalPrezzi("struttura");
            }}
          >
            <span style={{ textDecoration: "underline" }} className={"text-sm text-primary-500"}>
              Dettaglio prezzi<i style={{ textDecoration: "underline" }} className="las la-plus"></i>
            </span>
          </button>
        </div>
      </div>
    );
  };

  const badgeEvent = () => {
    return (
      <>
        {aperto == "1" && (
          <div
            className="flex-shrink-0 flex flex-col items-start"
            style={{ whiteSpace: "pre-wrap", maxWidth: "370px", overflow: "hidden", wordBreak: "break-word" }}
          >
            <span>
              {label_stato != "" && label_stato != null
                ? label_stato.replace(/\n/g, "<br/>")
                : "Seleziona il numero di persone e la tipologia per continuare"}
            </span>
          </div>
        )}
        {aperto == 3 && (
          <div className="flex flex-wrap justify-center lg:justify-start">
            {etichette_stati && (
              <>
                <div className="flex flex-wrap justify-center lg:justify-start">
                  {etichette_stati.length > 0 &&
                    etichette_stati.map((item: Etichette) => {
                      if (item.tipologia_id == null || item.tipologia_id == undefined) {
                        if (item?.stato_id == 3 && item.etichetta_stato != "" && item.etichetta_stato != null) {
                          return (
                            <>
                              <span className="text-center">
                                {label_stato != "" && label_stato != null ? label_stato : "Saranno presto disponibili nuove informazioni!"}
                              </span>
                              <Badge
                                className="animate-pulse text-center w-full text-3xl"
                                name={item.etichetta_stato != "" ? item.etichetta_stato : ""}
                                color="red"
                              />
                            </>
                          );
                        }
                      }
                    })}
                </div>
                {etichette_stati.length == 0 && <Badge className="animate-pulse w-full text-center text-3xl" name="CHIUSO" color="red" />}
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const renderAccordion = () => {
    return (
      <div className="flex flex-col space-y-2 p-4">
        {note_generali != null && note_generali != "" && (
          <FlightCardNote
            data={{
              titolo: title_note_generali,
              descrizione: note_generali.replace("$TA", ta).replace("$TB", tb),
              backColor: color_note_generali,
              open: open_accordion_note_generali,
            }}
          />
        )}
        {accordion_acconto != null && accordion_acconto != "" && (
          <FlightCardNote
            data={{
              titolo: accordion_acconto,
              descrizione: testo_accordion_acconto,
              backColor: color_accordion_acconto,
              open: open_accordion_acconto,
            }}
          />
        )}
        {title_note_token != null && title_note_token != "" && (
          <FlightCardNote
            data={{ titolo: title_note_token, descrizione: note_token, backColor: color_title_note_token, open: open_accordion_note_token }}
          />
        )}
        {arrayScelto?.stato == "RICHIESTA" &&
          accordion_richiestadiprenotazione != "" &&
          accordion_richiestadiprenotazione != null &&
          testo_accordion_richiestadiprenotazione != "" &&
          testo_accordion_richiestadiprenotazione != null && (
            <FlightCardNote
              data={{
                titolo: accordion_richiestadiprenotazione,
                descrizione: testo_accordion_richiestadiprenotazione,
                backColor: color_accordion_richiestediprenotazione,
                open: open_accordion_richiestediprenotazione,
              }}
            />
          )}
        {arrayScelto?.stato == "ATTESA" &&
          accordion_listadiattesa != "" &&
          accordion_listadiattesa != null &&
          testo_accordion_listadiattesa != "" &&
          testo_accordion_listadiattesa != null && (
            <FlightCardNote
              data={{
                titolo: accordion_listadiattesa,
                descrizione: testo_accordion_listadiattesa,
                backColor: color_accordion_listadiattesa,
                open: open_accordion_listadiattesa,
              }}
            />
          )}
        {accordion_bambini != "" &&
          accordion_bambini != null &&
          testo_accordion_bambini != "" &&
          testo_accordion_bambini != null &&
          bambini + bambiniBaby > 0 && (
            <FlightCardNote
              data={{
                titolo: accordion_bambini,
                descrizione: testo_accordion_bambini,
                backColor: color_accordion_bambini,
                open: open_accordion_bambini,
              }}
            />
          )}
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 py-2">
          <span>
            Domande?{" "}
            <span style={{ color: "blue", textDecorationLine: "underline" }}>
              <Link to={{ pathname: "/faq" }} target="_blank">
                Vai alle FAQ
              </Link>
            </span>
          </span>
        </div>
        <span className="text-xs">
          Procedendo si accettano automaticamente i termini{" "}
          <span className="text-primary-6000 text-xs" style={{ textDecoration: "underline" }}>
            <Link target={"_blanck"} to={{ pathname: `/cancellazione_rimborso/${evento_id}` }}>
              cancellazione e rimborso
            </Link>
          </span>
        </span>
      </div>
    );
  };

  const buttonVerificaDisp = () => {
    if (animalick == true) {
      return (
        <div className="pt-2">
          <hr />
          <div className="space-y-2 pt-4">
            {/* OLD -> aggiunto altro blocco e prima avevo solo paga_online == 0 con openModalPrezzi('struttura') */}
            {paga_online == 0 && paga_struttura == 1 && (
              <Button
                onClick={() => {
                  openModalPrezzi("struttura");
                }}
                sizeClass="px-8 py-0.5"
                fontSize="text-xs"
                className="bg-red-500 w-full border border-red-700 text-white hover:text-neutral-700 hover:bg-white font-medium"
              >
                Dettaglio prezzi!
              </Button>
            )}
            {paga_online == 1 && paga_struttura == 0 && (
              <Button
                onClick={() => {
                  openModalPrezzi("online");
                }}
                sizeClass="px-8 py-0.5"
                fontSize="text-xs"
                className="bg-red-500 w-full border border-red-700 text-white hover:text-neutral-700 hover:bg-white font-medium"
              >
                Dettaglio prezzi!
              </Button>
            )}
            <div className="flex items-center" style={{ justifyContent: "space-between" }}>
              <div>
                <i className="las la-male text-lg mr-2"></i>
                <span className="text-sm text-neutral-500">Adulti</span>
              </div>
              <div className="flex flex-col ml-auto">
                <span className="text-sm text-neutral-500">
                  {metodoPagamento == "struttura"
                    ? "x" + adulti + " €" + tariffa_struttura.toFixed(2)
                    : "x" + adulti + " €" + tariffa_online.toFixed(2)}
                </span>
              </div>
            </div>
            {bambini_ammessi == 1 ? (
              <>
                <div className="flex items-center" style={{ justifyContent: "space-between" }}>
                  <div>
                    <i className="las la-child text-lg mr-2"></i>
                    <span className="text-sm text-neutral-500">Bambini Da 4 a 12 anni</span>
                  </div>
                  <div className="flex flex-col ml-auto">
                    <span className="text-sm text-neutral-500">
                      {metodoPagamento == "struttura"
                        ? "x" + bambini + " €" + tariffa_struttura_bambino.toFixed(2)
                        : "x" + bambini + " €" + tariffa_online_bambino.toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="flex items-center" style={{ justifyContent: "space-between" }}>
                  <div>
                    <i className="las la-baby text-lg mr-2"></i>
                    <span className="text-sm text-neutral-500">Bambini da 0 a 3 anni</span>
                  </div>
                  <div className="flex flex-col ml-auto">
                    <span className="text-sm text-neutral-500">Gratis</span>
                  </div>
                </div>
              </>
            ) : (
              <div className="flex items-center" style={{ justifyContent: "space-between" }}>
                <div>
                  <i className="las la-child text-lg mr-2"></i>
                  <span className="text-sm text-neutral-500">Bambini</span>
                </div>
                <div className="flex flex-col ml-auto">
                  <span className="text-sm text-neutral-500">Non ammessi</span>
                </div>
              </div>
            )}
            {animali_ammessi == 1 ? (
              <div className="flex items-center" style={{ justifyContent: "space-between" }}>
                <div>
                  <i className="las la-dog text-lg mr-2"></i>
                  <span className="text-sm text-neutral-500">Animali</span>
                </div>
                <div className="flex flex-col ml-auto">
                  <span className="text-sm text-neutral-500">{animali ? "Si" : "No"}</span>
                </div>
              </div>
            ) : (
              <div className="flex items-center" style={{ justifyContent: "space-between" }}>
                <div>
                  <i className="las la-dog text-lg mr-2"></i>
                  <span className="text-sm text-neutral-500">Animali</span>
                </div>
                <div className="flex flex-col ml-auto">
                  <span className="text-sm text-neutral-500">Non ammessi</span>
                </div>
              </div>
            )}

            {arrayServiziTemp.length > 0 && (
              <div className="flex flex-col justify-between text-neutral-6000">
                {arrayServiziTemp.map((item: TipologiaServiziType) => {
                  return (
                    <div className="flex flex-row justify-between">
                      <span className="text-sm">
                        <i className="las la-wine-glass text-lg mr-2"></i>
                        {item.nome}
                      </span>{" "}
                      <span className="text-sm">
                        x{item.quantita} € {parseFloat(item.prezzo_adulto).toFixed(2)}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="flex items-center" style={{ justifyContent: "space-between" }}>
              <div>
                <i className="la la-euro text-lg mr-2"></i>
                <span className="text-sm text-neutral-500">
                  {metodoPagamento != "" ? (metodoPagamento == 'online' ? "Metodo di pagamento" : 'Pagherai in') : "Devi ancora selezionare un metodo di pagamento"}
                </span>
              </div>
              <div className="flex flex-col ml-auto">
                <span className="text-sm text-red-500" style={{ textTransform: "capitalize" }}>
                  {metodoPagamento}
                </span>
              </div>
            </div>
            <hr />
            <div className="flex justify-between font-semibold py-2">
              <span>Totale</span>
              {/* Cambiati i paga_online 0 o 1 in metodo di pagamento */}
              {prezzoServizioTipologiaAdulto == 0 && prezzoServizioTipologiaBambino == 0 && metodoPagamento == "online" && (
                <span>€ {(tariffa_online * adulti + tariffa_online_bambino * bambini + quota_una_tantum + costoProdottoOpzione).toFixed(2)}</span>
              )}
              {prezzoServizioTipologiaAdulto == 0 && prezzoServizioTipologiaBambino == 0 && metodoPagamento == "struttura" && (
                <span>
                  € {(tariffa_struttura * adulti + tariffa_struttura_bambino * bambini + quota_una_tantum + costoProdottoOpzione).toFixed(2)}
                </span>
              )}
              {(prezzoServizioTipologiaAdulto > 0 || (prezzoServizioTipologiaBambino > 0 && actionOpzioniEvento == 1)) &&
                metodoPagamento == "online" && (
                  <span>
                    €{" "}
                    {(
                      tariffa_online * adulti +
                      tariffa_online_bambino * bambini +
                      quota_una_tantum +
                      prezzoServizioTipologiaAdulto * adulti +
                      prezzoServizioTipologiaBambino * bambini
                    ).toFixed(2)}
                  </span>
                )}
              {(prezzoServizioTipologiaAdulto > 0 || (prezzoServizioTipologiaBambino > 0 && actionOpzioniEvento == 1)) &&
                metodoPagamento == "struttura" && (
                  <span>
                    €{" "}
                    {(
                      tariffa_online * adulti +
                      tariffa_online_bambino * bambini +
                      quota_una_tantum +
                      prezzoServizioTipologiaAdulto * adulti +
                      prezzoServizioTipologiaBambino * bambini
                    ).toFixed(2)}
                  </span>
                )}
            </div>
            <ButtonPrimary className="w-full" onClick={checkDisponibilita} loading={loading}>
              Verifica disponibilità
            </ButtonPrimary>
            <div className="flex justify-between text-neutral-6000 mt-2">
              <span>
                Domande?{" "}
                <span style={{ color: "blue", textDecorationLine: "underline" }}>
                  <Link to={{ pathname: "/faq" }} target="_blank">
                    Vai alle FAQ
                  </Link>
                </span>
              </span>
            </div>
          </div>
        </div>
      );
    }
  };

  const buttonCheckout = () => {
    return (
      <div className="space-y-2">
        {/* OLD -> uguale al blocco procedente */}
        {paga_online == 0 && paga_struttura == 1 && (
          <Button
            onClick={() => {
              openModalPrezzi("struttura");
            }}
            sizeClass="px-8 py-0.5"
            fontSize="text-xs"
            className="bg-red-500 w-full border border-red-700 text-white hover:text-neutral-700 hover:bg-white font-medium"
          >
            Dettaglio prezzi!
          </Button>
        )}
        {paga_online == 1 && paga_struttura == 0 && (
          <Button
            onClick={() => {
              openModalPrezzi("online");
            }}
            sizeClass="px-8 py-0.5"
            fontSize="text-xs"
            className="bg-red-500 w-full border border-red-700 text-white hover:text-neutral-700 hover:bg-white font-medium"
          >
            Dettaglio prezzi!
          </Button>
        )}
        <div className="flex items-center" style={{ justifyContent: "space-between" }}>
          <div>
            <i className="las la-male text-lg mr-2"></i>
            <span className="text-sm text-neutral-500">Adulti</span>
          </div>
          <div className="flex flex-col ml-auto">
            <span className="text-sm text-neutral-500">
              {metodoPagamento == "struttura" ? "x" + adulti + " €" + tariffa_struttura.toFixed(2) : "x" + adulti + " €" + tariffa_online.toFixed(2)}
            </span>
          </div>
        </div>
        {bambini_ammessi == 1 ? (
          <>
            <div className="flex items-center" style={{ justifyContent: "space-between" }}>
              <div>
                <i className="las la-child text-lg mr-2"></i>
                <span className="text-sm text-neutral-500">Bambini Da 4 a 12 anni</span>
              </div>
              <div className="flex flex-col ml-auto">
                <span className="text-sm text-neutral-500">
                  {metodoPagamento == "struttura"
                    ? "x" + bambini + " €" + tariffa_struttura_bambino.toFixed(2)
                    : "x" + bambini + " €" + tariffa_online_bambino.toFixed(2)}
                </span>
              </div>
            </div>
            <div className="flex items-center" style={{ justifyContent: "space-between" }}>
              <div>
                <i className="las la-baby text-lg mr-2"></i>
                <span className="text-sm text-neutral-500">Bambini da 0 a 3 anni</span>
              </div>
              <div className="flex flex-col ml-auto">
                <span className="text-sm text-neutral-500">Gratis</span>
              </div>
            </div>
          </>
        ) : (
          <div className="flex items-center" style={{ justifyContent: "space-between" }}>
            <div>
              <i className="las la-child text-lg mr-2"></i>
              <span className="text-sm text-neutral-500">Bambini</span>
            </div>
            <div className="flex flex-col ml-auto">
              <span className="text-sm text-neutral-500">Non ammessi</span>
            </div>
          </div>
        )}
        {animali_ammessi == 1 ? (
          <div className="flex items-center" style={{ justifyContent: "space-between" }}>
            <div>
              <i className="las la-dog text-lg mr-2"></i>
              <span className="text-sm text-neutral-500">Animali</span>
            </div>
            <div className="flex flex-col ml-auto">
              <span className="text-sm text-neutral-500">{animali ? "Si" : "No"}</span>
            </div>
          </div>
        ) : (
          <div className="flex items-center" style={{ justifyContent: "space-between" }}>
            <div>
              <i className="las la-dog text-lg mr-2"></i>
              <span className="text-sm text-neutral-500">Animali</span>
            </div>
            <div className="flex flex-col ml-auto">
              <span className="text-sm text-neutral-500">Non ammessi</span>
            </div>
          </div>
        )}
        {arrayServiziTemp.length > 0 && (
          <div className="flex flex-col justify-between text-neutral-6000">
            {arrayServiziTemp.map((item: TipologiaServiziType) => {
              return (
                <div className="flex flex-row justify-between">
                  <span className="text-sm">
                    <i className="las la-wine-glass text-lg mr-2"></i>
                    {item.nome}
                  </span>{" "}
                  <span className="text-sm">
                    x{item.quantita} € {parseFloat(item.prezzo_adulto).toFixed(2)}
                  </span>
                </div>
              );
            })}
          </div>
        )}
        <div className="flex items-center" style={{ justifyContent: "space-between" }}>
          <div>
            <i className="la la-euro text-lg mr-2"></i>
            <span className="text-sm text-neutral-500">
              {metodoPagamento != "" ? "Metodo di pagamento" : "Devi ancora selezionare un metodo di pagamento"}
            </span>
          </div>
          <div className="flex flex-col ml-auto">
            <span className="text-sm text-red-500" style={{ textTransform: "capitalize" }}>
              {metodoPagamento}
            </span>
          </div>
        </div>
        <div className="flex justify-between font-semibold py-2">
          <span>Totale</span>

          {prezzoServizioTipologiaAdulto == 0 && prezzoServizioTipologiaBambino == 0 && metodoPagamento == "online" && (
            <span>€ {(tariffa_online * adulti + tariffa_online_bambino * bambini + quota_una_tantum + costoProdottoOpzione).toFixed(2)}</span>
          )}
          {prezzoServizioTipologiaAdulto == 0 && prezzoServizioTipologiaBambino == 0 && metodoPagamento == "struttura" && (
            <span>€ {(tariffa_struttura * adulti + tariffa_struttura_bambino * bambini + quota_una_tantum + costoProdottoOpzione).toFixed(2)}</span>
          )}
          {(prezzoServizioTipologiaAdulto > 0 || (prezzoServizioTipologiaBambino > 0 && actionOpzioniEvento == 1)) && metodoPagamento == "online" && (
            <span>
              €{" "}
              {(
                tariffa_online * adulti +
                tariffa_online_bambino * bambini +
                quota_una_tantum +
                prezzoServizioTipologiaAdulto * adulti +
                prezzoServizioTipologiaBambino * bambini
              ).toFixed(2)}
            </span>
          )}
          {(prezzoServizioTipologiaAdulto > 0 || (prezzoServizioTipologiaBambino > 0 && actionOpzioniEvento == 1)) &&
            metodoPagamento == "struttura" && (
              <span>
                €{" "}
                {(
                  tariffa_online * adulti +
                  tariffa_online_bambino * bambini +
                  quota_una_tantum +
                  prezzoServizioTipologiaAdulto * adulti +
                  prezzoServizioTipologiaBambino * bambini
                ).toFixed(2)}
              </span>
            )}
        </div>
        <Link
          to={{
            pathname: "/checkout",
            state: {
              actionOpzioniEvento,
              animali_ammessi,
              bambini_ammessi,
              az: serviziSelected,
              evento_token,
              day,
              prezzoServizioTipologiaAdulto,
              prezzoServizioTipologiaBambino,
              arrayServiziTemp,
              adulti,
              totale_bambini_servizi,
              totale_adulti_servizi,
              ta,
              tb,
              note_generali,
              note_token,
              title_note_token,
              serviziSelected,
              referente,
              area_id,
              area,
              val_pren,
              val_pren_inferiore,
              tipologia,
              arrayScelto,
              evento_id,
              data_finale,
              referente_required,
              animali,
              quota_una_tantum,
              accordion_bambini,
              accordion_listadiattesa,
              accordion_richiestadiprenotazione,
              testo_accordion_bambini,
              testo_accordion_listadiattesa,
              testo_accordion_richiestadiprenotazione,
              acconto,
              color_accordion_listadiattesa,
              color_accordion_richiestediprenotazione,
              color_accordion_bambini,
              color_title_note_token,
              color_note_generali,
              accordion_acconto,
              testo_accordion_acconto,
              color_accordion_acconto,
              open_accordion_note_token,
              open_accordion_bambini,
              open_accordion_listadiattesa,
              open_accordion_richiestediprenotazione,
              open_accordion_acconto,
              open_accordion_note_generali,
              prezzoServizioTipologiaAdultoToken,
              prezzoServizioTipologiaBambinoToken,
              costoProdottoOpzione,
              metodoPagamento,
              text_tipologiaSoldOut,
              text_utenteAbilitato,
              text_richiestaCaparra,
              text_prenotazioneEsistente,
              text_swalCaparra,
              text_statoRichiestaOnline,
              text_statoAttesaOnline,
              text_statoAttesaStruttura,
              text_statoRichiestaStruttura,
              bambini,
              bambiniBaby
            },
          }}
        >
          <ButtonSecondary className="w-full">Vai al checkout</ButtonSecondary>
        </Link>
        <div className="flex justify-between text-neutral-6000 mt-2">
          <span>
            Domande?{" "}
            <span style={{ color: "blue", textDecorationLine: "underline" }}>
              <Link to={{ pathname: "/faq" }} target="_blank">
                Vai alle FAQ
              </Link>
            </span>
          </span>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap shadow-xl p-5">
        <div className="flex flex-row">
          <div>
            {images.length > 0 && (
              <>
                <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
                  {images.length > 1 ? (
                    <div
                      className="col-span-3 row-span-3 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                      onClick={() => handleOpenModal(0)}
                    >
                      <NcImage
                        containerClassName="display-block inset-0 w-full h-full"
                        className="object-cover w-full rounded-md sm:rounded-xl"
                        src={images[0].link}
                        prevImageHorizontal
                      />
                      {/* Opacità al passaggio del mouse sulla foto */}
                      <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                      {/* Fine: Opacità al passaggio del mouse sulla foto */}
                    </div>
                  ) : (
                    <div
                      className="col-span-4 row-span-3 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer flex justify-center items-center"
                      onClick={() => handleOpenModal(0)}
                    >
                      <NcImage
                        containerClassName="display-block inset-0 h-full"
                        className="object-cover rounded-md sm:rounded-xl"
                        src={images[0].link}
                        prevImageHorizontal
                      />
                      {/* Opacità al passaggio del mouse sulla foto */}
                      <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                      {/* Fine: Opacità al passaggio del mouse sulla foto */}
                    </div>
                  )}

                  {images.filter((e: any, i: number) => i >= 1 && i < 4).map((item: any, index: number) => (
                    <div key={index} className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 2 ? "block" : ""}`}>
                      <NcImage
                        containerClassName="aspect-w-3 aspect-h-2"
                        className="object-cover w-full h-full rounded-md sm:rounded-xl "
                        src={item.link || ""}
                        prevImageHorizontal
                      />
                      <div
                        className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                        onClick={() => handleOpenModal(index + 1)}
                      />
                    </div>
                  ))}
                  <div
                    className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                    onClick={() => handleOpenModal(0)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                    <span className="ml-2 text-neutral-800 text-sm font-medium">Vedi tutte le foto</span>
                  </div>
                </div>
                <ModalPhotos imgs={images} isOpen={isOpen} onClose={handleCloseModal} initFocus={openFocusIndex} />
              </>
            )}
          </div>
          <div className="pl-4">
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold text-primary-100">{evento?.titolo}</h2>
            <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
              <i className="las la-calendar text-2xl"></i>
              <span className="font-semibold text-xl">
                {day} {data_finale}
              </span>
              <i className="las la-clock text-2xl"></i>
              <span className="font-semibold text-xl">
                {ora?.slice(0, 5)} - {ora_fine?.slice(0, 5)}
              </span>
            </div>
            {(evento.menu_pdf != '' && evento.menu_pdf != null && evento.menu_pdf != undefined) &&
              <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
                <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3">
                  <i className="las la-file-pdf text-2xl"></i> {/* Icona PDF */}
                  <a href={`https://private.jooba.it/image/menupdf/${evento.menu_pdf}/${evento.id}`} target="_blank" rel="noopener noreferrer">
                    <span className="font-semibold text-xl">Visualizza Menu PDF </span>
                  </a>
                </div>
              </div>
            }
            {eventiCorrelati.length > 0 && (
              <div className="flex flex-col space-y-3 text-sm mt-2">
                <h2 className="font-semibold text-primary-100">Altre date disponibili per questo evento</h2>
                <Select className="mt-4" onChange={handleSelectChange}>
                  <option>Seleziona altre date</option>
                  {eventiCorrelati.map((e: any) => {
                    return <option value={e?.id}>{e?.value}</option>;
                  })}
                </Select>
                <span className="font-semibold text-xl">L'evento selezionato è in data: {data_finale}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="flex flex-col shadow-xl p-5 mt-5">
        {imagesAdd.length > 0 && (
          <>
            <h2 className="text-2xl font-semibold text-primary-100 mb-6 mt-6">Programma dell'evento</h2>
            <div className="relative grid grid-cols-2 sm:grid-cols-4 gap-1 sm:gap-2">
              {imagesAdd.map((item: any, index: number) => (
                <div key={index} className={`relative rounded-md sm:rounded-xl overflow-hidden`}>
                  <NcImage
                    containerClassName="aspect-w-3 aspect-h-4"
                    className="object-cover w-full h-full rounded-md sm:rounded-xl"
                    src={item.link || ""}
                    prevImageHorizontal
                  />
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={() => handleOpenModal1(index)}
                  />
                </div>
              ))}
            </div>
            <ModalPhotos imgs={imagesAdd} isOpen={isOpen1} onClose={handleCloseModal1} initFocus={openFocusIndex1} />
          </>
        )}
        <h2 className="text-2xl font-semibold text-primary-100 mt-6">{titolo_booking || "Regolamento iscrizione"}</h2>
        {evento?.des_offerta != "" && evento?.des_offerta != null && (
          <div className="py-2 mt-4">
            <FlightCardMirko data={{ titolo: "Descrizione evento", descrizione: evento?.des_offerta }} />
          </div>
        )}
        {subtitle_1 != null && des_subtitle_1 != "" && (
          <div className="py-2">
            <FlightCardMirko data={{ titolo: subtitle_1, descrizione: des_subtitle_1 }} />
          </div>
        )}
        {subtitle_2 != null && des_subtitle_2 != "" && (
          <div className="py-2">
            <FlightCardMirko data={{ titolo: subtitle_2, descrizione: des_subtitle_2 }} />
          </div>
        )}

        {subtitle_3 != null && des_subtitle_3 != "" && (
          <div className="py-2">
            <FlightCardMirko data={{ titolo: subtitle_3, descrizione: des_subtitle_3 }} />
          </div>
        )}

        {subtitle_4 != null && des_subtitle_4 != "" && (
          <div className="py-2">
            <FlightCardMirko data={{ titolo: subtitle_4, descrizione: des_subtitle_4 }} />
          </div>
        )}

        {subtitle_5 != null && des_subtitle_5 != "" && (
          <div className="py-2">
            <FlightCardMirko data={{ titolo: subtitle_5, descrizione: des_subtitle_5 }} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`nc-ListingExperiencesDetailPage  ${className}`} data-nc-id="ListingExperiencesDetailPage">
      <main className="container mt-11 flex">
        <div className="w-full mb-8 lg:pr-10 lg:space-y-10">
          {renderSection1()}
          <div className="lg:hidden block mt-10">{renderSidebar1()}</div>
          {renderSection2()}
        </div>
        <div className="hidden lg:block" style={{ width: 400 }}>
          {renderSidebar()}
        </div>

        {renderModalResumeAcquisto()}
      </main>
    </div>
  );
};

export default ListingExperiencesDetailPage;
